import {useDispatch, useSelector} from 'react-redux'
import * as A from '../../modules/auth'
import * as L from '../../modules/loading'
import * as E from '../../modules/error'
import * as M from '../../modules/modal'
import * as api from '../../lib/api'
import client from '../../lib/client'
import Cookies from 'js-cookie'
import {AppState} from '../../store/AppState'
import {useCallback, useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBell, faUser} from '@fortawesome/free-regular-svg-icons'
import MyinfoUserForm from '../../components/auth/MyinfoUserForm'
import MyinfoNewsForm from '../../components/auth/MyinfoNewsForm'

const MyinfoContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const {userInfo} = useSelector<AppState, A.State>(({auth}) => auth)
  const [menu, setMenu] = useState<string>('')

  // 회원탈퇴
  const onQuit = useCallback(() => {
    if (userInfo.userNo) {
      dispatch(
        M.setModal({
          open: true,
          title: '계정을 삭제 하시겠습니까?',
          type: M.MODAL_TYPE_CONFIRM,
          btnText: '삭제',
          callback: () => {
            api.userDelete(userInfo.userNo).then(
              () => {
                delete client.defaults.headers.common.Authorization

                Cookies.remove('accessToken')
                dispatch(A.setAccessToken(''))
                dispatch(
                  A.setUserInfo({
                    userNo: '',
                    email: '',
                    name: '',
                    pwdChgDt: '',
                    regDt: '',
                    roleCd: ''
                  })
                )
                navigate('/')
              },
              error => {
                console.log(error)
                dispatch(E.setError(error.message))
                navigate('/error')
              }
            )
          }
        })
      )
    }
  }, [dispatch, navigate, userInfo.userNo])

  // (비밀번호 변경) 인증 메일 전송
  const onCertSend = useCallback(
    (fn?: (result: object) => void) => {
      dispatch(
        M.setModal({
          open: true,
          title: '비밀번호 변경 링크를 전송하시겠습니까?',
          type: M.MODAL_TYPE_CONFIRM,
          btnText: '메일 전송',
          callback: () => {
            dispatch(L.setLoading(true))
            api
              .certSend(userInfo.email)
              .then(res => {
                if (res) {
                  fn && fn(res.data)
                }
              })
              .finally(() => dispatch(L.setLoading(false)))
          }
        })
      )
    },
    [dispatch, userInfo.email]
  )
  // const onCertSend = useCallback(

  //   (callback?: (status: string, message: string) => void) => {
  //     dispatch(L.setLoading(true))
  //     api
  //       .certSend(userInfo.email)
  //       .then(res => {
  //         if (res) {
  //           callback && callback(res.data.status, res.data.message)
  //         }
  //       })
  //       .finally(() => dispatch(L.setLoading(false)))
  //   },
  //   [dispatch, userInfo.email]
  // )

  // 로그아웃
  const onSignout = () => {
    dispatch(
      M.setModal({
        open: true,
        title: '로그아웃 하시겠습니까?',
        type: M.MODAL_TYPE_CONFIRM,
        btnText: '로그아웃',
        callback: () => {
          delete client.defaults.headers.common.Authorization

          Cookies.remove('accessToken')
          dispatch(A.setAccessToken(''))
          dispatch(
            A.setUserInfo({
              userNo: '',
              email: '',
              name: '',
              pwdChgDt: '',
              regDt: '',
              roleCd: ''
            })
          )
          navigate('/')
        }
      })
    )
  }

  useEffect(() => {
    const {mode} = params as {mode: string}
    setMenu(mode)
  }, [navigate, params])

  return (
    <section className="manage">
      <div className="inner">
        <div className="main myinfo-grid">
          <div className="left">
            <ul>
              <ol className={menu === 'user' ? 'selected' : ''}>
                <Link to="/myinfo/user">
                  <FontAwesomeIcon icon={faUser} />
                  내정보
                </Link>
              </ol>
              <ol className={menu === 'news' ? 'selected' : ''}>
                <Link to="/myinfo/news">
                  <FontAwesomeIcon icon={faBell} />
                  새소식
                </Link>
              </ol>
            </ul>
          </div>
          {menu === 'user' && (
            <MyinfoUserForm
              userInfo={userInfo}
              onQuit={onQuit}
              onCertSend={onCertSend}
              onSignout={onSignout}
            />
          )}
          {menu === 'news' && <MyinfoNewsForm />}
        </div>
      </div>
    </section>
  )
}

export default MyinfoContainer
