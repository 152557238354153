import {createSelector} from 'reselect'
import {AppState} from '../store/AppState'

// 액세스 토큰 전달자 함수
const getAccessToken = (state: AppState) => state.auth.accessToken

// 사용자 정보 전달자 함수
const getUserInfo = (state: AppState) => state.auth.userInfo

// 로그인 여부
export const getAuthorized = createSelector(
  [getAccessToken, getUserInfo],
  (accessToken, userInfo) => accessToken.length > 0 && !!userInfo
)

// 사용자 권한 여부
export const isUser = createSelector(
  [getAuthorized, getUserInfo],
  (isAuthorized, userInfo) => isAuthorized && userInfo.roleCd === 'ROLE_USER'
)

// 관리자 권한 여부
export const isAdmin = createSelector(
  [getAuthorized, getUserInfo],
  (isAuthorized, userInfo) => isAuthorized && userInfo.roleCd === 'ROLE_ADMIN'
)
