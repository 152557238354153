import IntroContainer from '../containers/landing/IntroContainer'
import CardContainer from '../containers/landing/CardContainer'
import GuideContainer from '../containers/landing/GuideContainer'
import FaqContainer from '../containers/landing/FaqContainer'
import FeatureContainer from '../containers/landing/FeatureContainer'

export default function LandingPage() {
  return (
    <>
      <IntroContainer />
      <GuideContainer />
      <CardContainer />
      <FaqContainer />
      <FeatureContainer />
    </>
  )
}
