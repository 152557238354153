import {Button} from '../common/Button'
import * as A from '../../modules/auth'
import {FC, useCallback, useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEnvelope} from '@fortawesome/free-solid-svg-icons'

type MyinfoUserFormProps = {
  onQuit: () => void
  onSignout: () => void
  onCertSend: (callback?: (res: object) => void) => void
  userInfo: A.IUser
}

const MyinfoUserForm: FC<MyinfoUserFormProps> = ({
  userInfo,
  onQuit,
  onCertSend,
  onSignout
}) => {
  const [changePwdMode, setChangePwdMode] = useState<boolean>(false)
  const [changePwdMsg, setChangePwdMsg] = useState<string>('')

  // 비밀번호 변경
  const handlePwdForm = useCallback(() => {
    setChangePwdMode(true)
    setChangePwdMsg('')
  }, [])

  // 비밀번호 변경 취소
  const handlePwdCancle = useCallback(() => {
    setChangePwdMode(false)
  }, [])

  // 비밀번호 변경 링크 전송
  const handlePwdConfirm = useCallback(() => {
    setChangePwdMsg('')
    onCertSend((res: object) => {
      const {status, message} = res as {status: string; message: string}
      setChangePwdMsg(message)
      if (status === 'S') setTimeout(() => setChangePwdMsg(''), 5000)
    })
  }, [onCertSend])

  // 사용자 계정 삭제
  const handleQuit = useCallback(() => {
    onQuit()
  }, [onQuit])

  // 로그아웃
  const handleSignout = useCallback(() => {
    onSignout()
  }, [onSignout])

  return (
    <div className="right user">
      <div>
        <h3>이메일</h3>
        <div>{userInfo.email}</div>
      </div>
      <div>
        <h3>닉네임</h3>
        <div>
          {userInfo.name}
          {!userInfo.name && '-'}
        </div>
      </div>
      <div>
        {!changePwdMode && (
          <>
            <h3>비밀번호</h3>
            <div>
              <p>마지막 비밀번호 변경일 : {userInfo.pwdChgDt}</p>
              <Button className="btn btn-password" onClick={handlePwdForm}>
                비밀번호 변경
              </Button>
            </div>
          </>
        )}
        {changePwdMode && (
          <>
            <h3>비밀번호</h3>
            <div>
              <p>
                아래 이메일로{' '}
                <span className="focus">&quot;비밀번호 변경 링크&quot;</span> 를
                전송합니다.
              </p>
              <p>이메일을 확인하여 비밀번호를 변경하시기 바랍니다.</p>
              <p className="email">
                <FontAwesomeIcon icon={faEnvelope} /> {userInfo.email}
              </p>
              <div className="btn-rows">
                <button className="btn btn-cancel" onClick={handlePwdCancle}>
                  취소
                </button>
                <button className="btn btn-password-send" onClick={handlePwdConfirm}>
                  메일 전송
                </button>
              </div>
              {changePwdMsg && <p className="msg">{changePwdMsg}</p>}
            </div>
          </>
        )}
      </div>
      <div>
        <h3>등급</h3>
        <div>일반 사용자</div>
      </div>
      <div>
        <h3>가입일시</h3>
        <div>{userInfo.regDt}</div>
      </div>
      <div>
        <h3 style={{color: '#fa6767'}}>계정 관리</h3>
        <div>
          <p>
            사용자 계정을 삭제하시면, 계정이 잠긴 후 일주일 뒤 모든 데이터가 삭제됩니다.
          </p>
          <p>삭제를 취소하시려면, 관리자에게 연락주시기 바랍니다.</p>
          <div className="btn-rows">
            <Button className="btn btn-account" onClick={handleQuit}>
              계정 삭제
            </Button>
            <Button className="btn btn-signout" onClick={handleSignout}>
              로그아웃
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyinfoUserForm
