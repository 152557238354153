import {useCallback, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import SigninForm from '../../components/auth/SigninForm'
import * as A from '../../modules/auth'
import * as api from '../../lib/api'
import {AppState} from '../../store/AppState'
import {useNavigate} from 'react-router-dom'
import client from '../../lib/client'
import Cookies from 'js-cookie'

const SinginContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {accessToken} = useSelector<AppState, A.State>(({auth}) => auth)

  // 로그인
  const onSignin = useCallback(
    (email: string, password: string, callback?: (result: object) => void) => {
      api
        .signin(email, password)
        .then(res => {
          if (res) {
            if (res.data.status === 'success') {
              // 토큰 설정
              const {authorization} = res.headers
              const token = authorization.substring(7)
              dispatch(A.setAccessToken(token))
              Cookies.set('accessToken', token, {expires: 1})
              client.defaults.headers.common.Authorization = `Bearer ${token}`
              // 사용자 정보 설정
              const {userNo, username, name, pwdChgDt, regDt, roleCd} = res.data.data
              dispatch(
                A.setUserInfo({
                  userNo,
                  email: username,
                  name,
                  pwdChgDt,
                  regDt,
                  roleCd
                })
              )
            }
            // 로그인 콜백 처리 (에러 메시지 표시 또는 이메일 기억하기 처리)
            callback && callback(res)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    [dispatch]
  )

  useEffect(() => {
    if (accessToken) {
      navigate('/')
    }
  }, [accessToken, navigate])

  return <SigninForm onSignin={onSignin} />
}

export default SinginContainer
