import card from '../../img/card.png'
import share from '../../img/share.png'

export default function CardContainer() {
  return (
    <section>
      <div className="inner">
        <div className="landing guide">
          <div className="desc">
            <img className="namecard" src={card} alt="namecard" />
            <h2>프로필을 만드세요</h2>
            <p>자신을 표현할 수 있는 다양한 항목이 존재합니다.</p>
            <p>여러 개의 프로필을 만들 수 있어요.</p>
            <p>
              <span className="work">업무용</span>
              <span className="personal">개인용</span> 구분하여 관리하세요.
            </p>
          </div>
          <div className="desc">
            <img className="share" src={share} alt="share" />
            <h2>링크를 공유하세요</h2>
            <p>자신을 소개할 때, 연락처를 교환할 때</p>
            <p>
              <span className="personal">Share URL</span> 링크를 통하여
            </p>
            <p>보다 쉽게 프로필을 공유할 수 있어요.</p>
          </div>
        </div>
      </div>
    </section>
  )
}
