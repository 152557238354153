import {Link} from 'react-router-dom'

const Logo = () => {
  return (
    <Link to="/" className="logo">
      intro<span>me</span>
    </Link>
  )
}

export default Logo
