import {Action} from 'redux'

export type State = string

// 액션 타입
export type SET_ERROR_MESSAGE_ACTION = Action<'@error/setError'> & {payload: State}

type Actions = SET_ERROR_MESSAGE_ACTION

// 액션 함수
export const setError = (payload: State): SET_ERROR_MESSAGE_ACTION => ({
  type: '@error/setError',
  payload
})

// 초기화
const initialState: State = ''

// 리듀서
export const reducer = (state: State = initialState, action: Actions) => {
  switch (action.type) {
    case '@error/setError':
      return action.payload
  }

  return state
}
