import {Action} from 'redux'

export type IUser = {
  userNo: string
  email: string
  name: string
  pwdChgDt: string
  regDt: string
  roleCd: string
}

export const ROLE_USER = 'ROLE_USER'
export const ROLE_ADMIN = 'ROLE_ADMIN'

// 상태
export type State = {
  accessToken: string
  userInfo: IUser
}

// 액션 타입
export type SET_ACCESS_TOKEN = Action<'@auth/SET_ACCESS_TOKEN'> & {payload: string}
export type SET_USER_INFO = Action<'@auth/SET_USER_INFO'> & {payload: IUser}
export type SIGNIN = Action<'@auth/SIGNIN'>
export type SIGNUP = Action<'@auth/SIGNUP'>
export type SIGNOUT = Action<'@auth/SIGNOUT'>

type Actions = SET_ACCESS_TOKEN | SET_USER_INFO | SIGNIN | SIGNUP | SIGNOUT

// 액션 함수
export const setAccessToken = (payload: string): SET_ACCESS_TOKEN => ({
  type: '@auth/SET_ACCESS_TOKEN',
  payload
})

export const setUserInfo = (payload: IUser): SET_USER_INFO => ({
  type: '@auth/SET_USER_INFO',
  payload
})

export const signin = (): SIGNIN => ({
  type: '@auth/SIGNIN'
})

export const signup = (): SIGNUP => ({
  type: '@auth/SIGNUP'
})

export const signout = (): SIGNOUT => ({
  type: '@auth/SIGNOUT'
})

// 초기값
const initialState = {
  accessToken: '',
  userInfo: {userNo: '', email: '', name: '', pwdChgDt: '', regDt: '', roleCd: ''}
}

// 리듀스
const auth = (state: State = initialState, action: Actions) => {
  switch (action.type) {
    case '@auth/SET_ACCESS_TOKEN':
      return {...state, accessToken: action.payload}
    case '@auth/SET_USER_INFO':
      return {...state, userInfo: action.payload}
  }

  return state
}

export default auth
