import './App.css'
import {BrowserRouter} from 'react-router-dom'
import RoutesSetup from './routes/RoutesSetup'
import {Provider as ReduxProvider} from 'react-redux'
import {useStore} from './store/useStore'
import Cookies from 'js-cookie'
import client from './lib/client'
import {setAccessToken, setUserInfo} from './modules/auth'
import * as api from './lib/api'
import ScrollToTop from './components/common/ScrollToTop'
import {setScreenHeight} from './lib/utils'
import {useEffect} from 'react'
import Modal from './components/common/Modal'
import Loading from './components/common/Loading'

function App() {
  const store = useStore()

  function loadUser() {
    const token = Cookies.get('accessToken')

    if (!token) return

    store.dispatch(setAccessToken(token))
    client.defaults.headers.common.Authorization = `Bearer ${token}`

    api.userInfo().then(res => {
      const {email, userNo, name, pwdChgDt, regDt, roleCd} = res.data
      store.dispatch(setUserInfo({email, userNo, name, pwdChgDt, regDt, roleCd}))
    })
  }

  loadUser()

  useEffect(() => {
    setScreenHeight()
  }, [])

  return (
    <ReduxProvider store={store}>
      <BrowserRouter>
        <Modal />
        <Loading />
        <ScrollToTop />
        <RoutesSetup />
      </BrowserRouter>
    </ReduxProvider>
  )
}

export default App
