import {Profile} from '../../modules/profile'
import {FC} from 'react'
import ProfileType from '../common/ProfileType'

type ProfileShareFormProps = {
  profile: Profile
}

const ProfileShareForm: FC<ProfileShareFormProps> = ({profile: p}) => {
  return <ProfileType profile={p} />
}

export default ProfileShareForm
