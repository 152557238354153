import {useParams} from 'react-router-dom'
import {useCallback, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import RecoveryForm from '../../components/auth/RecoveryForm'
import RecoverySetForm from '../../components/auth/RecoverySetForm'
import * as api from '../../lib/api'
import * as L from '../../modules/loading'
import RecoveryResultForm from '../../components/auth/RecoveryResultForm'

const RecoveryContainer = () => {
  const dispatch = useDispatch()
  const params = useParams()

  const [email, setEmail] = useState<string>('')
  const [recoveryId, setRecoveryId] = useState<string>('')
  const [status, setStatus] = useState<string>('')
  const [form, setForm] = useState<string>('')

  // 비밀번호 찾기 링크 전송
  const onRecovery = useCallback(
    (email: string) => {
      setStatus('')
      dispatch(L.setLoading(true))
      api
        .certSend(email)
        .then(res => {
          if (res) {
            setStatus(res.data.status)
            if (res.data.status !== 'NF') {
              setForm('recoveryResultForm')
            }
          }
        })
        .finally(() => dispatch(L.setLoading(false)))
    },
    [dispatch]
  )

  // 비밀번호 링크 정보 조회
  const onRecoveryInfo = useCallback((certId: string) => {
    api.certGet(certId).then(res => {
      if (res.data.status === 'NV') {
        // 존재하지 않는 링크 & 만료된 링크
        setStatus(res.data.status)
        setForm('recoveryResultForm')
      } else {
        setRecoveryId(res.data.data.certId)
        setEmail(res.data.data.email)
        setForm('recoverySetForm')
      }
    })
  }, [])

  // 비밀번호 변경
  const onRecoverySet = useCallback((certId: string, password: string) => {
    api.password(certId, password).then(res => {
      setStatus(res.data.status)
      setForm('recoveryResultForm')
    })
  }, [])

  useEffect(() => {
    const id = params['certId']
    if (id) {
      // 비밀번호 설정 링크로 들어오는 경우
      onRecoveryInfo(id)
    } else {
      // 비밀번호 찾기 링크로 들어오는 경우
      setForm('recoveryForm')
    }
  }, [onRecoveryInfo, params])

  return (
    <>
      {form === 'recoveryForm' && (
        <RecoveryForm onRecovery={onRecovery} status={status} />
      )}
      {form === 'recoverySetForm' && (
        <RecoverySetForm
          onRecoverySet={onRecoverySet}
          recoveryId={recoveryId}
          email={email}
        />
      )}
      {form === 'recoveryResultForm' && <RecoveryResultForm status={status} />}
    </>
  )
}

export default RecoveryContainer
