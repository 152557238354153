import {useSelector} from 'react-redux'
import Logo from '../../components/common/Logo'
import {AppState} from '../../store/AppState'
import * as E from '../../modules/error'
import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

export default function ServerErrorPage() {
  const error = useSelector<AppState, E.State>(({error}) => error)
  const navigate = useNavigate()

  useEffect(() => {
    if (error === '') {
      navigate('/')
    }
  }, [error, navigate])

  return (
    <>
      {error && (
        <div className="full-screen">
          <div>
            <p>500</p>
            <p>Server Error</p>
            <Logo />
          </div>
        </div>
      )}
    </>
  )
}
