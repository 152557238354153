import Logo from '../../components/common/Logo'

const WelcomePage = () => {
  return (
    <div className="wrapper">
      <div>
        <div className="title">
          <h2>
            <Logo />
          </h2>
        </div>
        <div className="result">
          <h2>회원가입 인증 메일이 전송되었습니다.</h2>
          <p>인증을 완료하시면, 회원가입이 완료됩니다.</p>
        </div>
      </div>
    </div>
  )
}

export default WelcomePage
