import {useCallback, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import ProfileAddForm from '../../components/profile/ProfileAddForm'
import * as P from '../../modules/profile'
import * as M from '../../modules/modal'
import * as api from '../../lib/api'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {AppState} from '../../store/AppState'

const ProfileAddContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {profile} = useSelector<AppState, AppState>(state => state)

  const location = useLocation()
  const params = useParams()

  const onProfileOne = useCallback(() => {
    const profileNo = params['profileNo']
    api
      .profileOne(`${profileNo}`)
      .then(res => {
        dispatch(P.profileOne(res.data))
      })
      .finally(() => {})
  }, [dispatch, params])

  const onProfileShareId = useCallback((callback?: (result: object) => void) => {
    api.profileShareId().then(res => {
      if (res.data) {
        callback && callback(res.data)
      }
    })
  }, [])

  const onProfileAdd = useCallback(
    (
      profileNo: string,
      typeCd: string,
      name: string,
      nickName: string,
      contact: string,
      link: string,
      email: string,
      job: string,
      place: string,
      status: string,
      intro: string,
      shareCd: string,
      shareId: string,
      memo: string,
      fileId: string
    ) => {
      const act = profileNo.length ? '수정' : '등록'

      dispatch(
        M.setModal({
          open: true,
          title: '프로필을 ' + act + ' 하시겠습니까?',
          type: M.MODAL_TYPE_CONFIRM,
          btnText: act,
          callback: () => {
            if (profileNo.length) {
              // 수정
              api
                .profileEdit(
                  profileNo,
                  typeCd,
                  name,
                  nickName,
                  contact,
                  link,
                  email,
                  job,
                  place,
                  status,
                  intro,
                  shareCd,
                  shareId,
                  memo,
                  fileId
                )
                .finally(() => {
                  navigate('/profile')
                })
            } else {
              // 등록
              api
                .profileAdd(
                  typeCd,
                  name,
                  nickName,
                  contact,
                  link,
                  email,
                  job,
                  place,
                  status,
                  intro,
                  shareCd,
                  shareId,
                  memo,
                  fileId
                )
                .finally(() => {
                  navigate('/profile')
                })
            }
          }
        })
      )
    },
    [dispatch, navigate]
  )

  const onProfileUpload = useCallback(
    (formData: FormData) => {
      api.profileUpload(formData).then(res => {
        dispatch(P.profileUpload(res.data))
      })
    },
    [dispatch]
  )

  const onProfileDel = useCallback(
    (profielNo: string) => {
      dispatch(
        M.setModal({
          open: true,
          title: '프로필을 삭제하시겠습니까?',
          type: M.MODAL_TYPE_CONFIRM,
          btnText: '삭제',
          callback: () => {
            api
              .profileDel(profielNo)
              .then(res => {
                dispatch(P.profileDel())
              })
              .finally(() => {
                navigate('/profile')
              })
          }
        })
      )
    },
    [dispatch, navigate]
  )

  useEffect(() => {
    const isEdit = location.pathname.includes('/edit')
    if (isEdit) {
      onProfileOne()
    }
  }, [onProfileOne, location.pathname])

  return (
    <>
      <ProfileAddForm
        profile={profile.profile}
        onProfileAdd={onProfileAdd}
        onProfileUpload={onProfileUpload}
        onProfileDel={onProfileDel}
        onProfileShareId={onProfileShareId}
      />
    </>
  )
}

export default ProfileAddContainer
