export default function IntroContainer() {
  return (
    <section className="intro">
      <div>
        <h1>
          <span>
            자신을 <b>표현</b>하고,
          </span>{' '}
          <span>
            {' '}
            <b>공유</b>하세요
          </span>
        </h1>
        <p>학생, 취준생, 프리랜서, 경력이 단절된 사람, 무언가를 준비 중인 사람</p>
        <p>프로필로 활용하세요</p>
      </div>
    </section>
  )
}
