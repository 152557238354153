import {useCallback, useEffect} from 'react'
import ProfileShareForm from '../../components/profile/ProfileShareForm'
import * as P from '../../modules/profile'
import * as api from '../../lib/api'
import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {AppState} from '../../store/AppState'

const ProfileShareContainer = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const {profile} = useSelector<AppState, AppState>(state => state)

  const onProfileShare = useCallback(() => {
    const shareId = params['shareId']
    api
      .profileShare(`${shareId}`)
      .then(res => {
        dispatch(P.profileShare(res.data))
      })
      .finally(() => {})
  }, [dispatch, params])

  useEffect(() => {
    onProfileShare()
  }, [onProfileShare])

  return <ProfileShareForm profile={profile.profile} />
}

export default ProfileShareContainer
