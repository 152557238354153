import {FC} from 'react'
import {Link} from 'react-router-dom'
import Logo from '../common/Logo'

type RecoveryResultFormProps = {
  status: string
}

const RecoveryResultForm: FC<RecoveryResultFormProps> = ({status}) => {
  return (
    <div className="wrapper">
      <div>
        <div className="title">
          <h2>
            <Logo />
          </h2>
        </div>
        <div className="result">
          {status === 'NV' && (
            <>
              <h2>비밀번호 변경 링크가 유효하지 않습니다.</h2>
              <p>
                비밀번호 변경을{' '}
                <Link className="btn-link" to="/recovery">
                  다시 시도
                </Link>{' '}
                하시기 바랍니다.
              </p>
            </>
          )}
          {status === 'E' && (
            <>
              <h2>유효한 비밀번호 변경 링크가 존재합니다.</h2>
              <p>
                &#8251; 비밀번호 변경 메일을 전송받지 못할 경우 관리자에게 문의 주시기
                바랍니다.
              </p>
            </>
          )}
          {status === 'S' && (
            <>
              <h2>비밀번호 변경 링크가 전송되었습니다.</h2>
              <p>메일을 확인하여 비밀번호를 변경하시기 바랍니다.</p>
            </>
          )}
          {status === 'C' && (
            <>
              <h2>비밀번호가 변경되었습니다.</h2>
              <p>
                변경된 비밀번호로{' '}
                <Link className="btn-link" to="/signin">
                  로그인
                </Link>{' '}
                하시기 바랍니다.
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
export default RecoveryResultForm
