import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons'
import {MouseEvent, useCallback, useState} from 'react'

type FaqType = Record<'faq1' | 'faq2' | 'faq3' | 'faq4', boolean>
const initialFaqState = {
  faq1: true,
  faq2: true,
  faq3: true,
  faq4: true
}

export default function FaqContainer() {
  const [{faq1, faq2, faq3, faq4}, setFaq] = useState<FaqType>(initialFaqState)

  const onFaqHide = useCallback(
    (key: string, val: boolean) => (e: MouseEvent<HTMLElement>) => {
      setFaq(obj => ({...obj, [key]: !val}))
    },
    []
  )

  return (
    <section>
      <div className="inner">
        <div className="landing">
          <p>FAQ</p>
          <div className="faqList">
            <div className="faq">
              <div onClick={onFaqHide('faq1', faq1)}>
                <span>introme 는 무엇인가요?</span>
                {faq1 && <FontAwesomeIcon icon={faChevronDown} />}
                {!faq1 && <FontAwesomeIcon icon={faChevronUp} />}
              </div>
              <div className={faq1 ? 'hide' : ''}>
                <p>프로필, 자신을 표현할 수 있는 수단입니다.</p>
                <p>온라인 명함으로 활용 할 수 있어요.</p>
                <p>
                  업무용 프로필 뿐만 아니라 아니라 퍼스널 브랜딩, 부캐 프로필 등으로
                  활용해보세요.
                </p>
              </div>
            </div>
            <div className="faq">
              <div onClick={onFaqHide('faq2', faq2)}>
                <span>introme 는 무료인가요?</span>
                {faq2 && <FontAwesomeIcon icon={faChevronDown} />}
                {!faq2 && <FontAwesomeIcon icon={faChevronUp} />}
              </div>
              <div className={faq2 ? 'hide' : ''}>
                <p>네, 개인은 무료로 이용할 수 있습니다.</p>
              </div>
            </div>
            <div className="faq">
              <div onClick={onFaqHide('faq3', faq3)}>
                <span>프로필을 어떻게 공유하나요?</span>
                {faq2 && <FontAwesomeIcon icon={faChevronDown} />}
                {!faq2 && <FontAwesomeIcon icon={faChevronUp} />}
              </div>
              <div className={faq3 ? 'hide' : ''}>
                <p>
                  프로필을 만들거나 수정할 때 공유 기능을 활성화하면, 유니크한
                  공유아이디가 생성됩니다.
                </p>
                <p>
                  다른 사람들은 공유 아이디가 포함된 링크를 통하여 프로필 정보에 접근할 수
                  있습니다.
                </p>
                <p>공유 기능을 비활성화 하면, 프로필 정보에 접근할 수 없습니다.</p>
              </div>
            </div>
            <div className="faq">
              <div onClick={onFaqHide('faq4', faq4)}>
                <span>모바일에서 사용가능한가요?</span>
                {faq4 && <FontAwesomeIcon icon={faChevronDown} />}
                {!faq4 && <FontAwesomeIcon icon={faChevronUp} />}
              </div>
              <div className={faq4 ? 'hide' : ''}>
                <p>
                  모바일 전용 앱은 없지만, 반응형 웹을 지원하기 때문에 모바일 브라우저에서
                  접속하시면 사용하실 수 있습니다.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
