import {FC, PropsWithChildren, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {getAuthorized, isAdmin, isUser} from '../modules/selector'
import {useSelector} from 'react-redux'
// import {ROLE_USER, ROLE_ADMIN} from '../modules/auth'

type RequireAuthProps = {
  roles: string[]
}

const RequireAuth: FC<PropsWithChildren<RequireAuthProps>> = ({children, roles}) => {
  const navigate = useNavigate()

  const loggedUser = useSelector(getAuthorized)
  // const user = useSelector(isUser)
  // const admin = useSelector(isAdmin)

  useEffect(() => {
    // 로그인 여부 체크
    if (!loggedUser) {
      navigate('/signin')
    }

    // let isRole = false

    // 역할 여부 체크
    // roles.forEach(role => {
    //   if (role === ROLE_USER && user) {
    //     isRole = true
    //   }

    //   if (role === ROLE_ADMIN && admin) {
    //     isRole = true
    //   }
    // })

    // if (!isRole) {
    //   alert('접근 권한이 없습니다.')
    //   navigate(-1)
    // }
  }, [navigate, loggedUser])

  return <>{children}</>
}

export default RequireAuth
