import {useCallback, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import CertifyForm from '../../components/auth/CertifyForm'
import * as api from '../../lib/api'
import * as M from '../../modules/modal'
import {useDispatch} from 'react-redux'

const CertifyContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const [valid, setValid] = useState<string>('')

  // 인증 정보 조회
  const onCertifyInfo = useCallback((certId: string) => {
    api.certGet(certId).then(res => {
      if (res) {
        setValid(res.data.status)
      }
    })
  }, [])

  // 인증 처리
  const onCertifyComplete = useCallback(() => {
    const {certId} = params as {certId: string}

    api.certPost(certId).then(res => {
      if (res.status === 200) {
        dispatch(
          M.setModal({
            open: true,
            title: '인증이 완료되었습니다.',
            type: M.MODAL_TYPE_ALERT,
            btnText: '확인',
            callback: () => {
              navigate('/signin')
            }
          })
        )
      }
    })
  }, [dispatch, navigate, params])

  useEffect(() => {
    const certId = params['certId']
    if (certId) {
      onCertifyInfo(certId)
    }
  }, [onCertifyInfo, params])

  return <CertifyForm valid={valid} onCertifyComplete={onCertifyComplete} />
}

export default CertifyContainer
