import {Action} from 'redux'

export type State = {
  open: boolean // 오픈 여부
  title: string // 모달 팝업 제목
  type: string // 모달 팝업 타입 (confirm, alert)
  btnText: string
  callback: () => void // 콜백 함수
}

// 모달 타입 변수
export const MODAL_TYPE_CONFIRM = 'confirm'
export const MODAL_TYPE_ALERT = 'alert'

// 액션 타입
export type SET_MODAL = Action<'@modal/setModal'> & {payload: State}

type Actions = SET_MODAL

// 액션 함수
export const setModal = (payload: State): SET_MODAL => ({
  type: '@modal/setModal',
  payload
})

// 초기화
const initialState: State = {
  open: false,
  title: '',
  type: '',
  btnText: '',
  callback: () => {}
}

// 리듀서
export const reducer = (state: State = initialState, action: Actions) => {
  switch (action.type) {
    case '@modal/setModal':
      return action.payload
  }

  return state
}
