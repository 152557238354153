import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircleUser} from '@fortawesome/free-solid-svg-icons'
import {Link} from 'react-router-dom'
import Logo from '../../components/common/Logo'
import * as A from '../../modules/auth'
import {useSelector} from 'react-redux'
import {AppState} from '../../store/AppState'

export default function Header() {
  const {accessToken, userInfo} = useSelector<AppState, A.State>(({auth}) => auth)

  return (
    <header>
      <nav className="fixed-top">
        <div className="container">
          <div>
            <Logo />
            <span className="desc">프로필 공유 서비스</span>
          </div>
          <ul className="gnb">
            {accessToken && userInfo.userNo && (
              <li className="signing">
                <Link to="/profile">
                  <FontAwesomeIcon
                    icon={faCircleUser}
                    size="lg"
                    style={{color: '#444'}}
                  />
                </Link>
              </li>
            )}
            {!accessToken && !userInfo.userNo && (
              <li>
                <Link to="/signin" className="btn">
                  시작하기
                </Link>
              </li>
            )}
          </ul>
        </div>
      </nav>
    </header>
  )
}
