import {Action} from 'redux'

export type State = boolean

// 액션 타입
export type SET_LOADING = Action<'@loading/setLoading'> & {payload: State}

type Actions = SET_LOADING

// 액션 함수
export const setLoading = (payload: State): SET_LOADING => ({
  type: '@loading/setLoading',
  payload
})

// 초기화
const initialState: State = false

// 리듀서
export const reducer = (state: State = initialState, action: Actions) => {
  switch (action.type) {
    case '@loading/setLoading':
      return action.payload
  }

  return state
}
