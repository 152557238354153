const ServiceyPage = () => {
  return (
    <div className="policy">
      <div className="title">이용약관</div>
      <div className="inner content">
        <div>
          <h3>제1조 목적</h3>
          <p>
            이 약관은 introme, 인트로미 (이하 '서비스')가 온라인으로 제공하는 인터넷
            사이트 (www.introme.xyz) 서비스의 이용과 관련하여 서비스와 회원 간의 권리,
            의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
          </p>
        </div>
        <div>
          <h3>제2조 용어의 정의</h3>
          <p>① 이 약관에서 사용하는 정의는 다음과 같습니다.</p>
          <p>
            1. '서비스'라 함은 온라인을 통하여 프로필 공유 서비스를 제공하는 주체를
            의미합니다.
          </p>
          <p>
            2. '회원'이라 함은 본 약관에 동의하고 프로필 공유 서비스 이용 자격을 부여받은
            자를 의미합니다.
          </p>
          <p>
            3. '프로필 공유 서비스'라 함은 회사가 회원에게 온라인으로 제공하는 캠핑 및
            이에 부수된 제반 서비스를 의미합니다.
          </p>
          <p>
            4. '계정(ID)'이라 함은 회원의 식별과 캠핑서비스 이용을 위하여 회원이 선정하고
            회사가 부여하는 문자, 숫자 또는 특수문자의 조합을 의미합니다.
          </p>
          <p>
            5. '계정정보'라 함은 회원의 계정, 이메일, 비밀번호, 닉네임 등 회원이 회사에
            제공한 일반정보 등 생성정보를 통칭합니다.
          </p>
          <p>
            6. '비밀번호'라 함은 회원이 부여받은 계정과 일치되는 회원임을 확인하고 회원의
            정보 및 권익보호를 위해 회원 자신이 선정하여 비밀로 관리하는 문자, 숫자 또는
            특수문자의 조합을 의미합니다.
          </p>
          <p>
            7. '게시물'이라 함은 회원이 서비스를 이용함에 있어 회원이 게시한 문자, 문서,
            그림, 음성, 영상 또는 이들의 조합으로 이루어진 모든 정보를 말합니다.
          </p>
          <p>
            ② 이 약관에서 사용하는 용어의 정의는 제1항 각호에서 정하는 것을 제외하고는
            관계법령 및 기타 일반적인 상관례에 의합니다.
          </p>
        </div>
        <div>
          <h3>제3조 회사정보 등의 제공</h3>
          <p>
            회사는 다음 각 호의 사항을 홈페이지(www.introme.xyz)에 게시하여, 회원이 이를
            쉽게 알 수 있도록 합니다. 다만, 개인정보처리방침과 약관은 회원이 연결화면을
            통하여 볼 수 있도록 할 수 있습니다.
          </p>
          <p>1. 서비스 명</p>
          <p>2. 개인정보처리방침</p>
          <p>3. 서비스이용약관</p>
        </div>
        <div>
          <h3>제4조 약관의 명시와 개정</h3>
          <p>
            ① 회사는 이 약관의 내용을 회원이 알 수 있도록 홈페이지(www.introme.xyz)에
            게시하거나 연결화면을 제공하는 방법으로 회원에게 공지합니다.
          </p>
          <p>
            ② 회사는 회원이 회사와 이 약관의 내용에 관하여 질의 및 응답을 할 수 있도록
            조치를 취합니다.
          </p>
        </div>
        <div>
          <h3>제5조 약관 외 준칙</h3>
          <p>
            이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 「전자상거래
            등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」,
            「정보통신망이용촉진 및 정보보호 등에 관한 법률」, 「콘텐츠산업진흥법」 등
            관련 법령에 따릅니다.
          </p>
        </div>
        <div>
          <h3>제6조 서비스 제공</h3>
          <p>회사는 회원에게 아래와 같은 서비스를 제공합니다.</p>
          <p>① 프로필 공유 서비스</p>
        </div>
        <div>
          <h3>제7조 이용신청 및 방법</h3>
          <p>
            ① 회사가 제공하는 서비스를 이용하고자 하는 자는 회사가
            홈페이지(www.introme.xyz)에서 제공하는 이용신청서를 작성하는 방법으로
            이용신청을 하여야 합니다.
          </p>
          <p>② 이용자는 이용신청시 회사에서 요구하는 제반 정보를 제공하여야 합니다.</p>
        </div>
        <div>
          <h3>제8조 이용신청의 승낙과 제한</h3>
          <p>
            ① 회사는 회사가 이용자에게 요구하는 정보에 대해 이용자가 실제 정보를 정확히
            기재하여 이용신청을 한 경우에 상당한 이유가 없는 한 이용신청을 승낙합니다.
          </p>
          <p>
            ② 회사는 다음 각 호의 어느 하나에 해당하는 이용신청에 대해서는 승낙을 하지
            않을 수 있습니다.
          </p>
          <p>
            ③ 회사는 다음 각 호의 어느 하나에 해당하는 경우에는 그 사유가 해소될 때까지
            승낙을 유보할 수 있습니다.
          </p>
          <p>1. 회사의 설비에 여유가 없거나 기술적 장애가 있는 경우</p>
          <p>2. 서비스 상의 장애가 발생한 경우</p>
          <p>3. 그 밖에 위 각 호에 준하는 사유로서 이용신청의 승낙이 곤란한 경우</p>
        </div>
        <div>
          <h3>제9조 회원 계정(ID) 및 비밀번호</h3>
          <p>
            ① 회사는 회원에 대하여 회원의 정보 보호, 서비스 이용안내 등의 편의를 위해
            회원이 선정한 일정한 문자, 숫자 또는 특수문자의 조합을 계정으로 부여합니다.
          </p>
          <p>
            ② 회사는 계정정보를 통하여 당해 회원의 서비스 이용가능 여부 등의 제반 회원
            관리업무를 수행합니다.
          </p>
          <p>
            ③ 회원은 자신의 계정정보를 선량한 관리자로서의 주의 의무를 다하여 관리하여야
            합니다. 회원이 본인의 계정정보를 소홀히 관리하거나 제3자에게 이용을
            승낙함으로써 발생하는 손해에 대하여는 회원에게 책임이 있습니다.
          </p>
          <p>
            ④ 비밀번호의 관리책임은 회원에게 있으며, 회원이 원하는 경우에는 보안상의 이유
            등으로 언제든지 변경이 가능합니다.
          </p>
          <p>⑤ 회원은 정기적으로 비밀번호를 변경하여야 합니다.</p>
        </div>
        <div>
          <h3>제10조 회원 정보의 제공 및 변경</h3>
          <p>
            ① 회원은 이 약관에 의하여 회사에 정보를 제공하여야 하는 경우에는 진실된 정보를
            제공하여야 하며, 허위정보 제공으로 인해 발생한 불이익에 대해서는 보호받지
            못합니다.
          </p>
          <p>
            ② 회원은 개인정보관리화면을 통하여 언제든지 자신의 개인정보를 열람하고 수정할
            수 있습니다. 다만, 서비스 관리를 위해 필요한 이메일, 계정(ID) 등은 수정이
            불가능합니다.
          </p>
          <p>
            ③ 회원은 회원가입 신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을
            하거나 기타 방법으로 회사에 대하여 그 변경사항을 알려야 합니다.
          </p>
          <p>
            ④ 제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임을
            지지 않습니다.
          </p>
        </div>
        <div>
          <h3>제11조 개인정보의 보호 및 관리</h3>
          <p>
            ① 회사는 관계 법령이 정하는 바에 따라 계정정보를 포함한 회원의 개인정보를
            보호하기 위해 노력합니다. 회원 개인정보의 보호 및 사용에 대해서는 관계법령 및
            회사가 별도로 고지하는 개인정보처리방침이 적용됩니다.
          </p>
          <p>
            ② 서비스의 일부로 제공되는 개별 서비스를 제외한 것으로서 홈페이지에서 단순히
            링크된 제3자 제공의 서비스에 대하여는 회사의 개인정보처리방침이 적용되지
            않습니다.
          </p>
          <p>
            ③ 회사는 회원의 귀책사유로 인하여 노출된 회원의 계정정보를 포함한 모든 정보에
            대해서 일체의 책임을 지지 않습니다.
          </p>
        </div>
        <div>
          <h3>제12조 회사의 의무</h3>
          <p>
            ① 회사는 관련 법령을 준수하고, 이 약관이 정하는 권리의 행사와 의무의 이행을
            신의에 따라 성실하게 합니다.
          </p>
          <p>
            ② 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보
            포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고
            준수합니다. 회사는 이 약관 및 개인정보처리방침에서 정한 경우를 제외하고는
            회원의 개인정보가 제3자에게 공개 또는 제공되지 않도록 합니다.
          </p>
          <p>
            ③ 회사는 계속적이고 안정적인 서비스의 제공을 위하여 서비스 개선을 하던 중
            설비에 장애가 생기거나 데이터 등이 멸실된 때에는 천재지변, 비상사태, 현재의
            기술로는 해결이 불가능한 결함 및 장애 등 부득이한 사유가 없는 한 지체 없이
            이를 수리 또는 복구하도록 최선의 노력을 다합니다.
          </p>
        </div>
        <div>
          <h3>제13조 회원의 의무</h3>
          <p>① 회원은 다음 행위를 하여서는 안 됩니다.</p>
          <p>1. 신청 또는 변경 시 허위내용의 기재</p>
          <p>2. 타인의 정보도용</p>
          <p>3. 회사의 임직원, 운영자, 기타 관계자를 사칭하는 행위</p>
          <p>4. 회사가 게시한 정보의 변경</p>
          <p>5. 회사가 금지한 정보(컴퓨터 프로그램 등)의 송신 또는 게시</p>
          <p>
            6. 회사가 제공 또는 승인하지 아니한 컴퓨터 프로그램이나 기기 또는 장치를 제작,
            배포, 이용, 광고하는 행위
          </p>
          <p>7. 회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해</p>
          <p>8. 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</p>
          <p>
            9. 외설 또는 폭력적인 말이나 글, 화상, 음향, 기타 공서양속에 반하는 정보를
            공개 또는 게시하는 행위
          </p>
          <p>
            10. 회사의 동의 없이 영리, 영업, 광고, 정치활동 등을 목적으로 서비스를
            사용하는 행위
          </p>
          <p>
            11. 기타 관련 법령에서 금지하거나 선량한 풍속 기타 사회통념상 허용되지 않는
            행위
          </p>
          <p>
            ② 회원은 이 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항, 회사가
            통지하는 사항 등을 확인하고 준수할 의무가 있습니다.
          </p>
        </div>
        <div>
          <h3>제14조 서비스의 변경 및 내용수정</h3>
          <p>
            ① 회원은 회사가 제공하는 캠핑서비스를 이 약관, 운영정책 및 회사가 설정한
            규칙에 따라 이용할 수 있습니다.
          </p>
        </div>
        <div>
          <h3>제15조 회원의 해제 및 해지</h3>
          <p>
            ① 회원은 캠핑서비스 이용계약을 해지(이하 '회원탈퇴'라 한다)할 수 있습니다.
            회원이 회원탈퇴를 신청한 경우 회사는 회원 본인 여부를 확인할 수 있으며, 해당
            회원이 본인으로 확인되는 경우에 회원의 신청에 따른 조치를 취합니다.
          </p>
          <p>
            ② 회원이 회원탈퇴를 원하는 경우에는 고객센터 및 서비스 내 회원탈퇴 절차를
            통하여 회원탈퇴를 할 수 있습니다.
          </p>
        </div>
        <div>
          <h3>제16조 회사의 해제 및 해지</h3>
          <p>
            ① 회사는 회원이 이 약관에서 정한 회원의 의무를 위반한 경우에는 회원에 대한
            사전 통보 후 계약을 해지할 수 있습니다. 다만, 회원이 현행법 위반 및 고의 또는
            중대한 과실로 회사에 손해를 입힌 경우에는 사전 통보 없이 이용계약을 해지할 수
            있습니다.
          </p>
        </div>
        <div>
          <h3>제17조 회원에 대한 서비스 이용제한</h3>
          <p>
            ① 회사는 회원에게 다음 각 호의 구분에 따라 회원의 캠핑서비스 이용을 제한할 수
            있습니다.
          </p>
          <p>1. 계정 이용제한 : 일정기간 또는 영구히 회원 계정의 이용을 제한</p>
          <p>
            ② 회사의 이용제한이 정당한 경우에 회사는 이용제한으로 인하여 회원이 입은
            손해를 배상하지 않습니다.
          </p>
        </div>
        <div>
          <h3>제18조 잠정조치로서의 이용제한</h3>
          <p>
            ① 회사는 다음 각 호에 해당하는 문제에 대한 조사가 완료될 때까지 계정을 정지할
            수 있습니다.
          </p>
          <p>1. 계정이 해킹 또는 도용당하였다는 정당한 신고가 접수된 경우</p>
          <p>2. 불법프로그램 사용자, 작업장 등 위법행위자로 합리적으로 의심되는 경우</p>
          <p>3. 그 밖에 위 각호에 준하는 사유로 계정의 잠정조치가 필요한 경우</p>
        </div>
        <div>
          <h3>제19조 손해배상</h3>
          <p>
            ① 회원이 본 약관을 위반하여 회사에 손해를 끼친 경우, 회원은 회사에 대하여 그
            손해에 대하여 배상할 책임이 있습니다.
          </p>
        </div>
        <div>
          <h3>제20조 회원의 고충처리 및 분쟁해결</h3>
          <p>
            ① 회사는 회원의 편의를 고려하여 회원의 의견이나 불만을 제시하는 방법을
            캠핑서비스 홈페이지에서 안내합니다.
          </p>
          <p>
            ② 회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될
            경우에는 합리적인 기간 내에 이를 신속하게 처리합니다. 다만, 처리에 장기간이
            소요되는 경우에는 회원에게 장기간이 소요되는 사유와 처리일정을
            홈페이지(www.introme.xyz)에 공지하거나 전자우편, 전화 또는 서면 등으로
            통보합니다.
          </p>
        </div>
        <div>
          <h3>제21조 회원에 대한 통지</h3>
          <p>
            ① 회사가 회원에게 통지를 하는 경우 회원이 지정한 전자우편주소, 전자메모 등으로
            할 수 있습니다.
          </p>
          <p>
            ② 회사는 회원 전체에게 통지를 하는 경우 7일 이상 홈페이지(www.introme.xyz)
            초기화면에 게시하거나 팝업화면 등을 제시함으로써 제1항의 통지에 갈음할 수
            있습니다.
          </p>
        </div>
        <div>
          <h3>제22조 재판권 및 준거법</h3>
          <p>
            본 약관은 대한민국 법률에 따라 규율되고 해석되며, 회사와 회원간에 발생한
            분쟁으로 소송이 제기되는 경우, 법령에 정한 절차에 따른 법원을 관할 법원으로
            합니다.
          </p>
        </div>
        <div>
          <p>본 약관은 2023년 10월 1일부터 시행합니다.</p>
        </div>
      </div>
    </div>
  )
}

export default ServiceyPage
