import Resizer from 'react-image-file-resizer'

export const maskingEmail = (email: string) => {
  if (email) {
    const mask = '*'.repeat(email.split('@')[0].length - 1)
    return email[0] + mask + email.slice(mask.length + 1, email.length)
  }
}

const emailRegExp =
  /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/i
// const passwordRegExp = /^[A-Za-z0-9]{8,20}$/
const passwordRegExp = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,20}$/

// 형식이 맞을 경우, true 리턴

export const checkEmail = (email: string) => {
  return emailRegExp.test(email)
}

export const checkPassword = (password: string) => {
  return passwordRegExp.test(password)
}

// 프로필 타입
export const transTypeCd = (typeCd: string) => {
  if (typeCd.toLowerCase() === 'business') {
    return '업무용'
  } else if (typeCd.toLowerCase() === 'personal') {
    return '개인용'
  }
}

// 스크린 높이 설정
export const setScreenHeight = () => {
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

// 파일 (사진) 사이즈 줄이기
export const resizeFile = (file: File) =>
  new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      'JPEG',
      100,
      0,
      uri => {
        resolve(uri)
      },
      'file'
    )
  })
