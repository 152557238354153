import Logo from '../../components/common/Logo'

export default function NotfoundPage() {
  return (
    <div className="full-screen">
      <div>
        <p>404</p>
        <p>Page Not Found</p>
        <Logo />
      </div>
    </div>
  )
}
