import {DetailedHTMLProps, FC, HTMLAttributes, useCallback, useEffect} from 'react'
import * as M from '../../modules/modal'
import {AppState} from '../../store/AppState'
import {useDispatch, useSelector} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircleExclamation, faCircleInfo} from '@fortawesome/free-solid-svg-icons'

type ReactDivProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
type ModalWrapProps = ReactDivProps & {
  open: boolean
}

const ModalWrap: FC<ModalWrapProps> = ({open, ...props}) => {
  const className = ['modal', open ? 'modal-open' : ''].join(' ')
  return <div {...props} className={className} />
}

type ModalContentProps = ReactDivProps & {}

const ModalContent: FC<ModalContentProps> = ({children, ...props}) => {
  return (
    <div {...props} className="modal-box">
      {children}
    </div>
  )
}

type ModalActionProps = ReactDivProps & {}

const ModalAction: FC<ModalActionProps> = ({...props}) => {
  return <div className="modal-action" {...props} />
}

const Modal = () => {
  const modal = useSelector<AppState, M.State>(({modal}) => modal)

  const dispatch = useDispatch()

  const onCancel = useCallback(() => {
    dispatch(
      M.setModal({open: false, title: '', type: '', btnText: '', callback: () => {}})
    )
  }, [dispatch])

  const onConfirm = useCallback(() => {
    modal.callback && modal.callback()
    dispatch(
      M.setModal({open: false, title: '', type: '', btnText: '', callback: () => {}})
    )
  }, [modal, dispatch])

  useEffect(() => {
    // 모달 팝업 시 스크롤 가리기
    if (modal.open) {
      document.body.style.overflow = 'hidden'
    }
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [modal.open])

  return (
    <>
      {modal.open && (
        <ModalWrap open={modal.open}>
          <ModalContent>
            <div className="modal-mark">
              {modal.type === M.MODAL_TYPE_ALERT && (
                <FontAwesomeIcon
                  icon={faCircleExclamation}
                  size="xl"
                  style={{color: '#444'}}
                />
              )}
              {modal.type === M.MODAL_TYPE_CONFIRM && (
                <FontAwesomeIcon icon={faCircleInfo} size="xl" style={{color: '#444'}} />
              )}
            </div>
            <div className="modal-title">
              <p>{modal.title}</p>
            </div>
            <ModalAction>
              {modal.type === M.MODAL_TYPE_ALERT && (
                <div className="btn-flex">
                  <button className="btn btn-ok" onClick={onConfirm}>
                    {modal.btnText !== '' ? modal.btnText : '확인'}
                  </button>
                </div>
              )}
              {modal.type === M.MODAL_TYPE_CONFIRM && (
                <div className="btn-rows btn-flex">
                  <button className="btn btn-cancel" onClick={onCancel}>
                    취소
                  </button>
                  <button className="btn btn-ok" onClick={onConfirm}>
                    {modal.btnText !== '' ? modal.btnText : '확인'}
                  </button>
                </div>
              )}
            </ModalAction>
          </ModalContent>
        </ModalWrap>
      )}
    </>
  )
}

export default Modal
