import {ChangeEvent, FC, FormEvent, useCallback, useEffect, useRef, useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEnvelope} from '@fortawesome/free-solid-svg-icons'
import {checkEmail} from '../../lib/utils'
import Logo from '../common/Logo'

type RecoveryFormProps = {
  onRecovery: (email: string) => void
  status: string
}

const RecoveryForm: FC<RecoveryFormProps> = ({onRecovery, status}) => {
  const [email, setEmail] = useState<string>('')
  const [msgEmail, setMsgEmail] = useState<string>('')

  // text
  const inputChanged = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmail(() => e.target.value)
  }, [])

  const handleRecovery = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      let isEmail = false

      if (email === '') {
        setMsgEmail(() => '이메일을 입력해주세요.')
        emailRef.current?.focus()
      } else {
        if (!checkEmail(email)) {
          setMsgEmail('이메일 형식이 유효하지 않습니다.')
          emailRef.current?.focus()
        } else {
          isEmail = true
          setMsgEmail('')
        }
      }

      if (isEmail) {
        onRecovery(email)
      }
    },
    [email, onRecovery]
  )

  const emailRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (status === 'NF') {
      setMsgEmail('이메일이 존재하지 않습니다.')
    }
    emailRef.current?.focus()
  }, [status])

  return (
    <div className="wrapper">
      <div>
        <div className="title">
          <h2>
            <Logo />
          </h2>
        </div>
        <form className="form" onSubmit={handleRecovery}>
          <div className={msgEmail.length ? 'valid' : ''}>
            <div className="input-container input-icon">
              <FontAwesomeIcon icon={faEnvelope} />
              <input
                type="text"
                ref={emailRef}
                defaultValue={email}
                onChange={inputChanged}
                placeholder="이메일"
              />
            </div>
            <div className="input-msg">
              <p>{msgEmail}</p>
            </div>
          </div>
          <div className="btn-flex">
            <button className="btn btn-signin">비밀번호 변경 메일 전송</button>
          </div>
          <div className="form-guide">
            <p>회원가입 시 사용한 이메일을 입력해주세요.</p>
            <p>비밀번호 변경 링크가 이메일로 전송됩니다.</p>
          </div>
        </form>
      </div>
    </div>
  )
}

export default RecoveryForm
