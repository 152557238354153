import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAddressCard, faServer, faVectorSquare} from '@fortawesome/free-solid-svg-icons'

export default function FeatureContainer() {
  return (
    <section>
      <div className="inner">
        <div className="landing weare">
          <p>WE ARE</p>
          <div className="featureList">
            <div className="feature">
              <FontAwesomeIcon icon={faAddressCard} />
              <h2>SIMPLE</h2>
              <p>화려하지 않지만, 단순하게 본질에 집중합니다.</p>
            </div>
            <div className="feature">
              <FontAwesomeIcon icon={faServer} />
              <h2>PERSISTENCE</h2>
              <p>지속 가능한 서비스를 제공하기 위해 노력합니다.</p>
            </div>
            <div className="feature">
              <FontAwesomeIcon icon={faVectorSquare} />
              <h2>LAB</h2>
              <p>자신을 표현할 수 있는 다양한 컨텐츠를 연구합니다.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
