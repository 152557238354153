import {FC, FormEvent, ChangeEvent, useState, useCallback, useRef, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEnvelope, faKey} from '@fortawesome/free-solid-svg-icons'
import Cookies from 'js-cookie'
import {checkEmail} from '../../lib/utils'
import Logo from '../common/Logo'

type SigninFormProps = {
  onSignin: (email: string, password: string, callback?: (res: object) => void) => void
}

type SigninFormType = Record<'email' | 'password', string>
const initialFormState = {email: '', password: ''}

const SigninForm: FC<SigninFormProps> = ({onSignin}) => {
  // 이메일, 비밀번호
  const [{email, password}, setForm] = useState<SigninFormType>(initialFormState)
  // 이메일 기억하기
  const [remember, setRemember] = useState(false)

  // 이메일, 비밀번호 유효성 메시지
  const [msgEmail, setMsgEmail] = useState<string>('')
  const [msgPassword, setMsgPassword] = useState<string>('')
  const [msgResult, setMsgResult] = useState<string>('')

  // 이메일, 비밀번호 입력
  const inputChanged = useCallback(
    (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setForm(obj => ({...obj, [key]: e.target.value}))
    },
    []
  )

  // 이메일 기억하기
  const checkChnaged = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setRemember(e.target.checked)
  }, [])

  // 로그인
  const handleSignin = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      let isEmail = false

      if (email === '') {
        setMsgEmail(() => '이메일을 입력해주세요.')
      } else {
        if (!checkEmail(email)) {
          setMsgEmail('이메일 형식이 유효하지 않습니다.')
        } else {
          isEmail = true
          setMsgEmail('')
        }
      }

      let isPassword = false

      if (password === '') {
        setMsgPassword('비밀번호를 입력해주세요.')
      } else {
        isPassword = true
        setMsgPassword('')
      }

      if (isEmail && isPassword) {
        setMsgResult('')
        // 로그인 처리
        onSignin(email, password, (res: object) => {
          const {data} = res as {data: object}
          const {status, message} = data as {status: string; message: string}

          if (status === 'success') {
            if (remember) {
              // console.log('이메일 기억하기 저장')
              Cookies.set('remember', email, {expires: 365})
            } else {
              // console.log('이메일 기억하기 삭제')
              Cookies.remove('remember')
            }
          } else {
            passwordRef.current?.focus()
            setMsgResult(message)
          }
        })
      } else {
        if (!isEmail) {
          emailRef.current?.focus()
        } else if (!isPassword) {
          passwordRef.current?.focus()
        }
      }
    },
    [email, password, remember, onSignin]
  )

  const emailRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (Cookies.get('remember') !== undefined) {
      const remail = Cookies.get('remember') as string

      setForm(obj => ({...obj, ['email']: remail}))
      setRemember(true)

      passwordRef.current?.focus()
    } else {
      emailRef.current?.focus()
    }
  }, [])

  return (
    <div className="wrapper">
      <div>
        <div className="title">
          <h2>
            <Logo />
          </h2>
          <p>프로필을 만들고, 공유하세요.</p>
        </div>
        <form className="form" onSubmit={handleSignin}>
          <div className={msgEmail.length ? 'valid' : ''}>
            <div className="input-container input-icon">
              <FontAwesomeIcon icon={faEnvelope} />
              <input
                name="email"
                type="text"
                ref={emailRef}
                value={email}
                autoComplete="off"
                placeholder="이메일"
                onChange={inputChanged('email')}
              />
            </div>
            <div className="input-msg">
              <p>{msgEmail}</p>
            </div>
          </div>
          <div className={msgPassword.length ? 'valid' : ''}>
            <div className="input-container input-icon">
              <FontAwesomeIcon icon={faKey} />
              <input
                name="password"
                type="password"
                ref={passwordRef}
                value={password}
                autoComplete="off"
                placeholder="비밀번호"
                onChange={inputChanged('password')}
              />
            </div>
            <div className="input-msg">
              <p>{msgPassword}</p>
            </div>
          </div>
          <div className="chkLogin">
            <div className="chkStyle">
              <input
                type="checkbox"
                id="chkRemember"
                name="chkRemember"
                checked={remember}
                onChange={checkChnaged}
              />
              <label htmlFor="chkRemember">이메일 기억하기</label>
            </div>
            <div>
              <Link to="/recovery">비밀번호 찾기</Link>
            </div>
          </div>
          <div className="signin-msg">
            {!msgEmail && !msgPassword && msgResult && <p>{msgResult}</p>}
          </div>
          <div className="btn-flex">
            <input type="submit" className="btn btn-signin" value="로그인" />
          </div>
          <div className="to-signup">
            <span>아직 회원이 아니신가요?</span> <Link to="/signup">회원가입</Link>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SigninForm
