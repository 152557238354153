import {FC} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faLocationDot,
  faShareNodes,
  faPenToSquare,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons'
import {Profile} from '../../modules/profile'
import {Link} from 'react-router-dom'
import {transTypeCd} from '../../lib/utils'

type ProfileCardProps = {
  profile: Profile
  mode: string
}

const ProfileCard: FC<ProfileCardProps> = ({profile: p, mode}) => {
  return (
    <>
      {p.profileNo && (
        <div className="profile-summary">
          {mode === 'MANAGE' && (
            <div className={'ribbon ' + (p.typeCd && p.typeCd.toLowerCase())}>
              {p.typeCd && <span>{transTypeCd(p.typeCd)}</span>}
              {!p.typeCd && <span>타입</span>}
            </div>
          )}
          <div className="name-job">
            <>
              {p.nickName && <h1>{p.nickName}</h1>}
              {!p.nickName && <h1 className="placeholder">닉네임</h1>}
            </>
            <>
              {p.job && <p>{p.job}</p>}
              {!p.job && <p className="placeholder">직업</p>}
            </>
          </div>
          <>
            {p.status && <p className="status">{p.status}</p>}
            {!p.status && <p className="status placeholder">상태</p>}
          </>
          <ul>
            <ol>
              {p.place && (
                <>
                  <FontAwesomeIcon icon={faLocationDot} />
                  <span>{p.place}</span>
                </>
              )}
              {!p.place && <span className="placeholder">장소</span>}
            </ol>
            <ol>
              {p.link && <span>{p.link}</span>}
              {!p.link && <span className="placeholder">링크</span>}
            </ol>
            <ol>
              {p.email && (
                <>
                  <FontAwesomeIcon icon={faEnvelope} />
                  <span>{p.email}</span>
                </>
              )}
              {!p.email && <span className="placeholder">메일</span>}
            </ol>
          </ul>
          <div className="btn-group">
            {(mode === 'SHARE' || mode === 'MANAGE') && p.shareCd === 'PUBLIC' && (
              <Link to={`/share/${p.shareId}`} target="_blank" title="공유">
                <FontAwesomeIcon
                  icon={faShareNodes}
                  size="lg"
                  style={{color: '#86a5c2'}}
                />
              </Link>
            )}
            {mode === 'MANAGE' && (
              <Link to={`/profile/edit/${p.profileNo}`} className="btn-edit" title="수정">
                <FontAwesomeIcon icon={faPenToSquare} size="lg" style={{color: '#fff'}} />
              </Link>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default ProfileCard
