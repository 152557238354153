import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFaceFlushed} from '@fortawesome/free-regular-svg-icons'

const MyinfoNewsForm = () => {
  return (
    <div className="right news">
      <FontAwesomeIcon icon={faFaceFlushed} />
      <p>소식이 존재하지 않습니다.</p>
      {/* <div> */}
      {/* </div> */}
    </div>
  )
}

export default MyinfoNewsForm
