const PrivacyPage = () => {
  return (
    <div className="policy">
      <div className="title">개인정보처리방침</div>
      <div className="inner content">
        <div>
          <h3>1. 개인정보의처리 목적</h3>
          <p>
            인트로미 ('https://www.introme.co.kr'이하 '인트로미') 은(는) 다음의 목적을
            위하여 개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지
            않습니다.
          </p>
          <p>
            - 고객 가입의사 확인, 고객에 대한 서비스 제공에 따른 본인 식별 &middot; 인증,
            회원자격 유지 &middot; 관리
          </p>
        </div>
        <div>
          <h3>2. 개인정보의 처리 및 보유 기간</h3>
          <p>
            ① 인트로미 는 정보주체로부터 개인정보를 수집할 때 동의 받은 개인정보 보유
            &middot; 이용기간 또는 법령에 따른 개인정보 보유 &middot; 이용기간 내에서
            개인정보를 처리 &middot; 보유합니다.
          </p>
          <p>② 구체적인 개인정보 처리 및 보유 기간은 다음과 같습니다.</p>
          <p>- 고객 가입 및 관리 : 서비스 이용계약 또는 회원가입 해지시까지</p>
        </div>
        <div>
          <h3>
            3. 정보주체와 법정대리인의 권리 &middot; 의무 및 그 행사방법 이용자는
            개인정보주체로써 다음과 같은 권리를 행사할 수 있습니다.
          </h3>
          <p>
            ① 정보주체는 인트로미 에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를
            행사할 수 있습니다.
          </p>
          <p>1. 개인정보 열람요구</p>
          <p>2. 오류 등이 있을 경우 정정 요구</p>
          <p>3. 삭제요구</p>
          <p>4. 처리정지 요구</p>
        </div>
        <div>
          <h3>4. 처리하는 개인정보의 항목</h3>
          <p>① 인트로미 는 다음의 개인정보 항목을 처리하고 있습니다.</p>
          <p>1. 서비스 회원가입 및 관리</p>
          <p>
            - 필수항목 : 이메일, 비밀번호, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보
          </p>
        </div>
        <div>
          <h3>5. 개인정보의 파기</h3>
          <p>
            인트로미 는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당
            개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.
          </p>
          <p>- 파기절차</p>
          <p>
            이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의
            서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시
            파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른
            목적으로 이용되지 않습니다.
          </p>
          <p>- 파기기한</p>
          <p>
            이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의
            종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의
            종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한
            것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.
          </p>
        </div>
        <div>
          <h3>6. 개인정보 자동 수집 장치의 설치 &middot; 운영 및 거부에 관한 사항</h3>
          <p>
            ① 인트로미 는 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로
            불러오는 '쿠키(cookie)'를 사용합니다.
          </p>
          <p>
            ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터
            브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에
            저장되기도 합니다.
          </p>
          <p>
            가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및
            이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보
            제공을 위해 사용됩니다.
          </p>
          <p>
            나. 쿠키의 설치 &middot; 운영 및 거부 : 웹브라우저 상단의 도구 &#9654; 인터넷
            옵션 &#9654; 개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.
          </p>
          <p>
            다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.
          </p>
        </div>
        <div>
          <h3>7. 개인정보 보호책임자</h3>
          <p>
            ① 인트로미 는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와
            관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보
            보호책임자를 지정하고 있습니다.
          </p>
          <p>▶ 개인정보 보호책임자</p>
          <p>성명 : 오재성</p>
          <p>이메일 : labirdworks@gmail.com</p>
          <p>
            ② 정보주체께서는 인트로미 의 서비스(또는 사업)을 이용하시면서 발생한 모든
            개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보
            보호책임자에게 문의하실 수 있습니다. 인트로미 는 정보주체의 문의에 대해 지체
            없이 답변 및 처리해드릴 것입니다.
          </p>
        </div>
        <div>
          <h3>8. 개인정보 처리방침 변경</h3>
          <p>
            ① 이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의
            추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을
            통하여 고지할 것입니다.
          </p>
        </div>
        <div>
          <h3>9. 개인정보의 안전성 확보 조치</h3>
          <p>
            인트로미 는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한
            기술적/관리적 및 물리적 조치를 하고 있습니다.
          </p>
          <p>1. 해킹 등에 대비한 기술적 대책</p>
          <p>
            인트로미 는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기
            위하여 보안프로그램을 설치하고 주기적인 갱신 &middot; 점검을 하며 외부로부터
            접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고
            있습니다.
          </p>
          <p>2. 개인정보의 암호화</p>
          <p>
            이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알
            수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을
            사용하는 등의 별도 보안기능을 사용하고 있습니다.
          </p>
        </div>
        <div>
          <h3>10. 기타</h3>
          <p>개인정보처리방침은 2023년 10월 1일 부터 시행됩니다.</p>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPage
