import {FC, useMemo} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCirclePlus, faGear} from '@fortawesome/free-solid-svg-icons'
import {Link} from 'react-router-dom'
import {Profile} from '../../modules/profile'
import * as L from '../../modules/loading'
import * as A from '../../modules/auth'
import ProfileCard from '../common/ProfileCard'

type ProfileListFormProps = {
  profiles: Profile[]
  loading: L.State
  userInfo: A.IUser
}

const ProfileListForm: FC<ProfileListFormProps> = ({profiles, loading, userInfo}) => {
  // 프로필 목록
  const children = useMemo(
    () =>
      profiles &&
      profiles.map(p => <ProfileCard key={p.profileNo} profile={p} mode="MANAGE" />),
    [profiles]
  )

  return (
    <section className="manage">
      <div className="inner">
        <div className="main">
          <div className="my-info">
            <div>
              <div>
                <b>기본 정보</b>
                <Link to="/myinfo/user">
                  <FontAwesomeIcon icon={faGear} />
                  설정
                </Link>
              </div>
              <div>
                <span>{userInfo.email}</span>
              </div>
            </div>
            <div>
              <div>
                <b>최근 소식</b>
                <Link to="/myinfo/news">더 보기</Link>
              </div>
            </div>
          </div>
          <div className="profile-list">
            {!loading && children}
            {!loading && profiles.length < 4 && (
              <div key="0" className="profile-summary-plus">
                <Link to="/profile/add" title="추가">
                  <FontAwesomeIcon icon={faCirclePlus} size="2xl" />
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProfileListForm
