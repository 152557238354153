import {FC, useCallback} from 'react'
import {Link} from 'react-router-dom'
import Logo from '../common/Logo'

type CertifyFormProps = {
  valid: string
  onCertifyComplete: () => void
}

const CertifyForm: FC<CertifyFormProps> = ({valid, onCertifyComplete}) => {
  const handleComplate = useCallback(() => {
    onCertifyComplete()
  }, [onCertifyComplete])

  return (
    <div className="wrapper">
      <div>
        <div className="title">
          <h2>
            <Logo />
          </h2>
        </div>
        <div className="result">
          {valid === 'V' && (
            <>
              <h2>회원가입을 환영합니다.</h2>
              <div className="btn-flex">
                <button className="btn btn-signin" onClick={handleComplate}>
                  인증 완료
                </button>
              </div>
              <p>인증을 완료하시면, 서비스 이용이 가능합니다.</p>
            </>
          )}
          {valid === 'NV' && (
            <>
              <h2>인증 링크가 유효하지 않습니다.</h2>
              <p>
                <Link to="/" className="btn-link">
                  처음으로
                </Link>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default CertifyForm
