import {Link, useLocation} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faShare} from '@fortawesome/free-solid-svg-icons'
import {useCallback, useEffect, useState} from 'react'
import ProfileCard from '../../components/common/ProfileCard'
import {Profile} from '../../modules/profile'
import * as api from '../../lib/api'

export default function CardContainer() {
  const {key} = useLocation()
  const [profileList, setProfileList] = useState<Profile[]>([])
  const [shareId, setShareId] = useState<string>('')

  const onProfileShare = useCallback(() => {
    api.profileShareList().then(res => {
      if (res.data) {
        setShareId(res.data[0].shareId)
        setProfileList(res.data)
      }
    })
  }, [])

  useEffect(() => {
    onProfileShare()
  }, [key, onProfileShare])

  return (
    <section className="bg">
      <div className="inner">
        <div className="landing">
          <p>SHARING</p>
          <div className="share-format">
            <Link to={`https://introme.xyz/share/${shareId}`} target="_blank">
              <FontAwesomeIcon icon={faShare} style={{color: '#444'}} />
              introme.xyz/share/<span>{shareId}</span>
            </Link>
          </div>
          <div className="share-list">
            {profileList &&
              profileList.map(p => (
                <ProfileCard key={p.profileNo} profile={p} mode="SHARE" />
              ))}
          </div>
        </div>
      </div>
    </section>
  )
}
