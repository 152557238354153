import {Link} from 'react-router-dom'
import Logo from '../../components/common/Logo'

export default function Footer() {
  return (
    <footer>
      <div className="inner">
        <div className="upper">
          <Logo />
        </div>
        <div className="lower">
          <p>&copy; 2023 introme</p>
          <ul>
            <li>
              <Link to="/policy/service">이용약관</Link>
            </li>
            <li>
              <Link to="/policy/privacy">개인정보처리방침</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}
