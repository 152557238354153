import {ChangeEvent, FC, FormEvent, useCallback, useEffect, useRef, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {checkEmail, checkPassword} from '../../lib/utils'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEnvelope, faKey} from '@fortawesome/free-solid-svg-icons'
import Logo from '../common/Logo'

type SignupFormProps = {
  onSignup: (email: string, password: string, callback?: (res: object) => void) => void
}

type SignupFormType = Record<'email' | 'password', string>

const initialFormState = {
  email: '',
  password: ''
}

const SignupForm: FC<SignupFormProps> = ({onSignup}) => {
  const navigate = useNavigate()

  // 이메일, 비밀번호
  const [{email, password}, setForm] = useState<SignupFormType>(initialFormState)
  // 약관
  const [policy, setPolicy] = useState<boolean>(false)

  // 이메일, 비밀번호, 약관 메시지
  const [msgEmail, setMsgEmail] = useState<string>('')
  const [msgPassword, setMsgPassword] = useState<string>('')
  const [msgPolicy, setMsgPolicy] = useState<string>('')

  // 이메일, 비밀번호 입력
  const inputChanged = useCallback(
    (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setForm(obj => ({...obj, [key]: e.target.value}))
    },
    []
  )

  // 이용약관 및 개인정보처리방침 체크
  const inputChangeChecked = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPolicy(e.target.checked)
  }, [])

  // 회원가입
  const handleSignup = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      let isEmail = false

      if (email === '') {
        setMsgEmail(() => '이메일을 입력해주세요.')
      } else {
        if (!checkEmail(email)) {
          setMsgEmail('이메일 형식이 유효하지 않습니다.')
        } else {
          isEmail = true
          setMsgEmail('')
        }
      }

      let isPassword = false

      if (password === '') {
        setMsgPassword('비밀번호를 입력해주세요.')
      } else {
        if (!checkPassword(password)) {
          setMsgPassword(
            '영문 대/소문자, 숫자, 특수문자를 혼합하여 8자 이상 20자 이하로 입력해주세요.'
          )
        } else {
          isPassword = true
          setMsgPassword('')
        }
      }

      let isPolicy = false

      if (!policy) {
        setMsgPolicy('이용약관 및 개인정보처리방침에 반드시 동의하여야 합니다.')
      } else {
        isPolicy = true
        setMsgPolicy('')
      }

      if (isEmail && isPassword && isPolicy) {
        onSignup(email, password, (res: object) => {
          const {data} = res as {data: object}
          const {status, message} = data as {status: string; message: string}
          if (status === 'E') {
            // 이메일 중복
            setMsgEmail(message)
            emailRef.current?.focus()
          } else if (status === 'C') {
            // 회원가입 성공
            navigate('/welcome')
          }
        })
      } else {
        if (!isEmail) {
          emailRef.current?.focus()
        } else if (!isPassword) {
          passwordRef.current?.focus()
        }
      }
    },
    [email, password, policy, onSignup, navigate]
  )

  const emailRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    emailRef.current?.focus()
  }, [])

  return (
    <div className="wrapper">
      <div>
        <div className="title">
          <h2>
            <Logo />
          </h2>
          <p>나 자신을 소개하세요.</p>
        </div>
        <form name="frmSignup" className="form" onSubmit={handleSignup}>
          <div className={msgEmail.length ? 'valid' : ''}>
            <div className="input-container input-icon">
              <FontAwesomeIcon icon={faEnvelope} />
              <input
                type="text"
                name="email"
                defaultValue={email}
                ref={emailRef}
                placeholder="이메일"
                autoComplete="off"
                onChange={inputChanged('email')}
              />
            </div>
            <div className="input-msg">
              <p>{msgEmail}</p>
            </div>
          </div>
          <div className={msgPassword.length ? 'valid' : ''}>
            <div className="input-container input-icon">
              <FontAwesomeIcon icon={faKey} />
              <input
                type="password"
                name="password"
                defaultValue={password}
                ref={passwordRef}
                placeholder="비밀번호"
                autoComplete="off"
                onChange={inputChanged('password')}
              />
            </div>
            <div className="input-msg">
              <p>{msgPassword}</p>
            </div>
          </div>
          <div className={msgPolicy.length ? 'valid' : ''}>
            <div className="chkStyle">
              <input
                type="checkbox"
                id="chkPolicy"
                name="chkPolicy"
                defaultChecked={policy}
                onChange={inputChangeChecked}
              />
              <label htmlFor="chkPolicy" className="policy">
                <Link to="/policy/service">이용약관</Link> 및{' '}
                <Link to="/policy/privacy">개인정보처리방침</Link> 동의
              </label>
            </div>
            <div className="input-msg">
              <p>{msgPolicy}</p>
            </div>
          </div>
          <div className="btn-flex">
            <button className="btn btn-signup">회원가입</button>
          </div>
          <div className="to-signin">
            <span>회원가입 하셨나요?</span> <Link to="/signin">로그인</Link>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SignupForm
