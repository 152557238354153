import {combineReducers} from 'redux'

import auth from '../modules/auth'
import * as P from '../modules/profile'
import * as L from '../modules/loading'
import * as E from '../modules/error'
import * as M from '../modules/modal'

export const rootReducer = combineReducers({
  auth,
  profile: P.reducer,
  loading: L.reducer,
  error: E.reducer,
  modal: M.reducer
})
