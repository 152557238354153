import {ChangeEvent, FC, FormEvent, useCallback, useEffect, useRef, useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faKey, faEnvelope} from '@fortawesome/free-solid-svg-icons'
import {checkPassword} from '../../lib/utils'
import Logo from '../common/Logo'

type RecoverySetFormProps = {
  onRecoverySet: (recoveryId: string, password: string) => void
  recoveryId: string
  email: string
}

type RecoverySetFormType = Record<'password' | 'passwordConfirm', string>

const initialFormState = {
  password: '',
  passwordConfirm: ''
}

const RecoverySetForm: FC<RecoverySetFormProps> = ({
  onRecoverySet,
  recoveryId,
  email
}) => {
  const [{password, passwordConfirm}, setForm] =
    useState<RecoverySetFormType>(initialFormState)

  const [msgPassword, setMsgPassword] = useState<string>('')
  const [msgPasswordConfirm, setMsgPasswordConfirm] = useState<string>('')

  const inputChanged = useCallback(
    (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setForm(obj => ({...obj, [key]: e.target.value}))
    },
    []
  )

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      setMsgPassword('')
      setMsgPasswordConfirm('')

      let isPassword = false

      if (password === '') {
        setMsgPassword('비밀번호를 입력해주세요.')
        passwordRef.current?.focus()
      } else {
        if (!checkPassword(password)) {
          setMsgPassword(
            '영문 대/소문자, 숫자, 특수문자를 혼합하여 8자 이상 20자 이하로 입력해주세요.'
          )
          passwordRef.current?.focus()
        } else {
          isPassword = true
          setMsgPassword('')
        }
      }

      let isPasswordConfirm = false

      if (passwordConfirm === '') {
        setMsgPasswordConfirm('비밀번호 확인을 입력해주세요.')
        passwordConfirmRef.current?.focus()
      } else {
        if (!checkPassword(passwordConfirm)) {
          setMsgPasswordConfirm(
            '영문 대/소문자, 숫자, 특수문자를 혼합하여 8자 이상 20자 이하로 입력해주세요.'
          )
          passwordConfirmRef.current?.focus()
        } else {
          isPasswordConfirm = true
          setMsgPasswordConfirm('')
        }
      }

      if (isPassword && isPasswordConfirm) {
        if (password === passwordConfirm) {
          onRecoverySet(recoveryId, password)
        } else {
          setMsgPasswordConfirm('비밀번호가 일치하지 않습니다.')
        }
      } else {
        if (!isPassword) {
          passwordRef.current?.focus()
        } else if (!isPasswordConfirm) {
          passwordConfirmRef.current?.focus()
        }
      }
    },
    [password, passwordConfirm, recoveryId, onRecoverySet]
  )

  const passwordRef = useRef<HTMLInputElement>(null)
  const passwordConfirmRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    passwordRef.current?.focus()
  }, [])

  return (
    <div className="wrapper">
      <div className="recovery-set">
        <div className="title">
          <h2>
            <Logo />
          </h2>
        </div>
        <form className="form" onSubmit={handleSubmit}>
          <div className="input-container input-icon readonly">
            <FontAwesomeIcon icon={faEnvelope} />
            <input
              type="text"
              name="email"
              defaultValue={email}
              placeholder="이메일"
              readOnly
              autoComplete="off"
              className="readonly"
            />
          </div>
          <div className={msgPassword.length ? 'valid' : ''}>
            <div className="input-container input-icon">
              <FontAwesomeIcon icon={faKey} />
              <input
                type="password"
                name="password"
                ref={passwordRef}
                defaultValue={password}
                placeholder="비밀번호"
                onChange={inputChanged('password')}
              />
            </div>
            <div className="input-msg">
              <p>{msgPassword}</p>
            </div>
          </div>
          <div className={msgPasswordConfirm.length ? 'valid' : ''}>
            <div className="input-container input-icon">
              <FontAwesomeIcon icon={faKey} />
              <input
                type="password"
                name="passwordConfirm"
                ref={passwordConfirmRef}
                defaultValue={passwordConfirm}
                placeholder="비밀번호 확인"
                onChange={inputChanged('passwordConfirm')}
              />
            </div>
            <div className="input-msg">
              <p>{msgPasswordConfirm}</p>
            </div>
          </div>
          <div className="btn-flex">
            <button className="btn btn-signin">비밀번호 변경</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default RecoverySetForm
