import {
  FC,
  useCallback,
  useState,
  ChangeEvent,
  FocusEvent,
  FormEvent,
  SyntheticEvent,
  useEffect,
  useRef
} from 'react'
import {Link} from 'react-router-dom'
import {Profile} from '../../modules/profile'
import Divide from '../common/Divide'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faUser,
  faXmark,
  faLocationDot,
  faCaretDown,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons'
import {faPenToSquare} from '@fortawesome/free-regular-svg-icons'
import {checkEmail, transTypeCd, resizeFile} from '../../lib/utils'

type ProfileAddFormProps = {
  profile: Profile
  onProfileAdd: (
    profileNo: string,
    type: string,
    name: string,
    nickName: string,
    contact: string,
    link: string,
    email: string,
    job: string,
    place: string,
    status: string,
    intro: string,
    shareCd: string,
    shareId: string,
    memo: string,
    fileId: string
  ) => void
  onProfileUpload: (formData: FormData) => void
  onProfileDel: (profileNo: string) => void
  onProfileShareId: (callback?: (res: object) => void) => void
}

type ProfileFormType = Record<
  | 'profileNo'
  | 'typeCd'
  | 'name'
  | 'nickName'
  | 'contact'
  | 'link'
  | 'email'
  | 'job'
  | 'place'
  | 'status'
  | 'intro'
  | 'shareCd'
  | 'shareId'
  | 'memo'
  | 'fileId'
  | 'fileUrl',
  string
>
const initialFormState = {
  profileNo: '',
  typeCd: '',
  name: '',
  nickName: '',
  contact: '',
  link: '',
  email: '',
  job: '',
  place: '',
  status: '',
  intro: '',
  shareCd: 'PRIVATE',
  shareId: '',
  memo: '',
  fileId: '',
  fileUrl: ''
}

const ProfileAddForm: FC<ProfileAddFormProps> = ({
  profile,
  onProfileAdd,
  onProfileUpload,
  onProfileDel,
  onProfileShareId
}) => {
  // 프로필 데이터
  const [
    {
      profileNo,
      typeCd,
      name,
      nickName,
      contact,
      link,
      email,
      job,
      place,
      status,
      intro,
      shareCd,
      shareId,
      memo,
      fileId,
      fileUrl
    },
    setForm
  ] = useState<ProfileFormType>(initialFormState)

  // 이메일 메시지
  const [msgEmail, setMsgEmail] = useState<string>('')

  // 프로필 사진 여부
  const [edit, setEdit] = useState<boolean>(false)
  // 프로필 화면 설정 (front / back)
  const [screen, setScreen] = useState<string>('front')

  // text, checkbox, radio
  const inputChanged = useCallback(
    (key: string) => (e: ChangeEvent<any>) => {
      if (key === 'shareCd') {
        if (e.target.value === 'PUBLIC') {
          onProfileShareId((res: object) => {
            const {data: uuid} = res as {data: string}
            setForm(obj => ({...obj, shareId: uuid}))
          })
        } else {
          setForm(obj => ({...obj, shareId: ''}))
        }
      }

      setForm(obj => ({...obj, [key]: e.target.value}))
    },
    [onProfileShareId]
  )

  // text, checkbox, radio
  const inputFocused = useCallback(
    (key: string) => (e: FocusEvent<any>) => {
      if (key === 'intro') {
        setScreen('back')
        cardRef.current?.classList.add('rotate')
      } else {
        setScreen('front')
        cardRef.current?.classList.remove('rotate')
      }

      const index = arrProfileForm.indexOf(key)
      arrProfileFormRef.current[index].classList.add('highlight')
    },
    []
  )

  // text, checkbox, radio
  const inputBlured = useCallback(
    (key: string) => (e: FocusEvent<any>) => {
      const index = arrProfileForm.indexOf(key)
      arrProfileFormRef.current[index].classList.remove('highlight')

      if (key === 'email') {
        if (email) {
          if (!checkEmail(email)) {
            setMsgEmail('이메일 형식이 유효하지 않습니다.')
          } else {
            setMsgEmail('')
          }
        } else {
          setMsgEmail('')
        }
      } else if (key === 'intro') {
        setScreen('front')
        cardRef.current?.classList.remove('rotate')
      }
    },
    [email]
  )

  // textarea
  const textAreaChanged = useCallback(
    (key: string) => (e: ChangeEvent<HTMLTextAreaElement>) => {
      setForm(obj => ({...obj, [key]: e.target.value}))
    },
    []
  )

  // 사진 편집
  const fileEdit = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      if (!edit) {
        fileEditRef.current?.classList.add('open')
        fileEditRef.current?.classList.remove('close')
        setEdit(true)
      } else {
        fileEditRef.current?.classList.add('close')
        fileEditRef.current?.classList.remove('open')
        setEdit(false)
      }
    },
    [edit]
  )

  // 사진 (file click)
  const fileClicked = useCallback((e: SyntheticEvent) => {
    e.stopPropagation()
    fileRef.current?.click()
  }, [])

  const fileClicked2 = useCallback((e: SyntheticEvent) => {
    e.stopPropagation()
  }, [])

  // 사진 선택 시 (file change)
  const fileChanged = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation()
      const files: FileList | null = e.target.files
      if (files) {
        for (let i = 0; i < files.length; i++) {
          const file: File | null = files.item(i)
          if (file) {
            var formData = new FormData()
            const compressedFile = resizeFile(file)
            compressedFile.then(data => {
              const f = data as File
              formData.append('file', f)
              onProfileUpload(formData)
            })
          }
        }
      }
    },
    [onProfileUpload]
  )

  // 사진 초기화
  const fileInit = useCallback((e: SyntheticEvent) => {
    e.stopPropagation()
    setForm(obj => ({...obj, fileId: ''}))
    setForm(obj => ({...obj, fileUrl: ''}))
  }, [])

  const typeOptions = [
    {label: '선택', value: ''},
    {label: '업무용', value: 'BUSINESS'},
    {label: '개인용', value: 'PERSONAL'}
  ]

  // select
  const selectChanged = useCallback(
    (key: string) => (e: ChangeEvent<HTMLSelectElement>) => {
      setForm(obj => ({...obj, [key]: e.target.value}))
    },
    []
  )

  // 삭제
  const handleDelete = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      onProfileDel(`${profile.profileNo}`)
    },
    [profile, onProfileDel]
  )

  // 등록, 수정
  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      onProfileAdd(
        profileNo,
        typeCd,
        name,
        nickName,
        contact,
        link,
        email,
        job,
        place,
        status,
        intro,
        shareCd,
        shareId,
        memo,
        fileId
      )
    },
    [
      onProfileAdd,
      profileNo,
      typeCd,
      name,
      nickName,
      contact,
      link,
      email,
      job,
      place,
      status,
      intro,
      shareCd,
      shareId,
      memo,
      fileId
    ]
  )

  // Front, Back 스크린
  const handleScreen = useCallback(
    (e: SyntheticEvent) => {
      if (screen === 'front') {
        setScreen('back')
        cardRef.current?.classList.add('rotate')
      } else {
        setScreen('front')
        cardRef.current?.classList.remove('rotate')
      }
    },
    [screen]
  )

  useEffect(() => {
    if (profile) {
      setForm({
        profileNo: profile.profileNo || '',
        typeCd: profile.typeCd || typeCd,
        name: profile.name || '',
        nickName: profile.nickName || nickName,
        contact: profile.contact || '',
        link: profile.link || link,
        email: profile.email || email,
        job: profile.job || job,
        place: profile.place || place,
        status: profile.status || status,
        intro: profile.intro || intro,
        shareCd: profile.shareCd || shareCd,
        shareId: profile.shareId || shareId,
        memo: profile.memo || memo,
        fileId: profile.fileId || fileId,
        fileUrl: profile.fileUrl || fileUrl
      })

      if (profile.email && !checkEmail(profile.email)) {
        setMsgEmail('이메일 형식이 유효하지 않습니다.')
      } else {
        setMsgEmail('')
      }
    }
  }, [profile])

  const fileRef = useRef<HTMLInputElement>(null)
  const fileEditRef = useRef<HTMLUListElement>(null)
  const cardRef = useRef<HTMLDivElement>(null)

  const arrProfileForm = [
    'typeCd',
    'nickName',
    'job',
    'place',
    'link',
    'email',
    'intro',
    'status'
  ]
  const arrProfileFormRef = useRef(new Array(arrProfileForm.length))

  return (
    <div className="inner">
      <div className="main">
        <form onSubmit={handleSubmit} className="profile-grid">
          <div className="profile-form">
            <div>
              <div>
                <span>타입</span>
              </div>
              <div className="profile-typeCd">
                <select
                  name="typeCd"
                  value={typeCd}
                  onFocus={inputFocused('typeCd')}
                  onBlur={inputBlured('typeCd')}
                  onChange={selectChanged('typeCd')}>
                  {typeOptions.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <FontAwesomeIcon icon={faCaretDown} />
              </div>
            </div>
            <div>
              <div>
                <span>닉네임</span>
              </div>
              <div>
                <input
                  name="nickName"
                  type="text"
                  autoComplete="off"
                  defaultValue={nickName}
                  onFocus={inputFocused('nickName')}
                  onBlur={inputBlured('nickName')}
                  onChange={inputChanged('nickName')}
                />
              </div>
            </div>
            <div>
              <div>
                <span>직업</span>
              </div>
              <div>
                <input
                  name="job"
                  type="text"
                  autoComplete="off"
                  defaultValue={job}
                  onFocus={inputFocused('job')}
                  onBlur={inputBlured('job')}
                  onChange={inputChanged('job')}
                />
              </div>
            </div>
            <div>
              <div>
                <span>장소</span>
              </div>
              <div>
                <input
                  name="place"
                  type="text"
                  autoComplete="off"
                  defaultValue={place}
                  onFocus={inputFocused('place')}
                  onBlur={inputBlured('place')}
                  onChange={inputChanged('place')}
                />
              </div>
            </div>
            <div>
              <div>
                <span>링크</span>
              </div>
              <div>
                <input
                  name="link"
                  type="text"
                  autoComplete="off"
                  defaultValue={link}
                  onFocus={inputFocused('link')}
                  onBlur={inputBlured('link')}
                  onChange={inputChanged('link')}
                />
              </div>
            </div>
            <div className={msgEmail.length ? 'valid' : ''}>
              <div>
                <span>이메일</span>
                <span>{msgEmail}</span>
              </div>
              <div>
                <input
                  name="email"
                  type="text"
                  autoComplete="off"
                  defaultValue={email}
                  onFocus={inputFocused('email')}
                  onBlur={inputBlured('email')}
                  onChange={inputChanged('email')}
                />
              </div>
            </div>
            <div>
              <div>
                <span>상태</span>
              </div>
              <div>
                <input
                  name="status"
                  type="text"
                  autoComplete="off"
                  defaultValue={status}
                  onFocus={inputFocused('status')}
                  onBlur={inputBlured('status')}
                  onChange={inputChanged('status')}
                />
              </div>
            </div>
            <div>
              <div>
                <span>소개</span>
              </div>
              <div>
                <textarea
                  name="intro"
                  defaultValue={intro}
                  onFocus={inputFocused('intro')}
                  onChange={textAreaChanged('intro')}
                  rows={3}></textarea>
              </div>
            </div>
            <Divide />
            <div>
              <div>
                <span>공유</span>
              </div>
              <div className="radio-group">
                <div>
                  <input
                    type="radio"
                    id="private"
                    name="shareCd"
                    value="PRIVATE"
                    onChange={inputChanged('shareCd')}
                    checked={shareCd === 'PRIVATE'}
                  />
                  <label htmlFor="private">미공유</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="public"
                    name="shareCd"
                    value="PUBLIC"
                    onChange={inputChanged('shareCd')}
                    checked={shareCd === 'PUBLIC'}
                  />
                  <label htmlFor="public">공유</label>
                </div>
              </div>
              <div>
                <input
                  name="shareId"
                  type="text"
                  defaultValue={shareId}
                  readOnly
                  className="readonly"
                  placeholder="공유 아이디"
                />
              </div>
            </div>
            <Divide />
            <div>
              <div>
                <span>메모</span>
              </div>
              <div>
                <textarea
                  name="memo"
                  defaultValue={memo}
                  onChange={textAreaChanged('memo')}
                  rows={3}></textarea>
              </div>
            </div>

            <div className="btn-rows btn-flex">
              <Link to="/profile" className="btn btn-cancel">
                취소
              </Link>
              {profile.profileNo && (
                <input type="submit" className="btn btn-ok" value="수정" />
              )}
              {!profile.profileNo && (
                <input type="submit" className="btn btn-ok" value="등록" />
              )}
            </div>
          </div>
          <div className="profile-preview">
            <div className="profile-desc">
              <p>PREVIEW</p>
            </div>
            <div className="profile-type">
              <div className="profile-card" ref={cardRef} onClick={handleScreen}>
                <div className="profile-front">
                  {profile.profileNo && (
                    <div className="btn-delete" onClick={handleDelete}>
                      <FontAwesomeIcon icon={faXmark} style={{color: '#fff'}} size="xl" />
                    </div>
                  )}
                  <div
                    className={'ribbon ' + (typeCd && typeCd.toLowerCase())}
                    ref={e => {
                      arrProfileFormRef.current[0] = e
                    }}>
                    {typeCd && <span>{transTypeCd(typeCd)}</span>}
                    {!typeCd && <span>타입</span>}
                  </div>
                  <article>
                    <div
                      className={'img ' + (!fileUrl && 'img-none')}
                      style={{backgroundImage: `url(${fileUrl})`}}>
                      {!fileUrl && (
                        <FontAwesomeIcon icon={faUser} style={{color: '#86A5C2'}} />
                      )}
                      <div className="img-control">
                        <div onClick={fileEdit}>
                          <FontAwesomeIcon
                            icon={faPenToSquare}
                            style={{color: '#86A5C2'}}
                            size="lg"
                          />
                          <span>Edit</span>
                        </div>
                        <ul className="close" ref={fileEditRef}>
                          <ol onClick={fileClicked}>업로드</ol>
                          <ol onClick={fileInit}>초기화</ol>
                        </ul>
                      </div>
                    </div>
                    <input
                      type="file"
                      ref={fileRef}
                      onClick={fileClicked2}
                      onChange={fileChanged}
                      accept="image/*"
                    />
                    <h1
                      ref={e => {
                        arrProfileFormRef.current[1] = e
                      }}>
                      {nickName && <span>{nickName}</span>}
                      {!nickName && <span className="placeholder">닉네임</span>}
                    </h1>
                    <h2
                      ref={e => {
                        arrProfileFormRef.current[2] = e
                      }}>
                      {job && <span>{job}</span>}
                      {!job && <span className="placeholder">직업</span>}
                    </h2>
                    <h2
                      ref={e => {
                        arrProfileFormRef.current[3] = e
                      }}
                      className="place">
                      {place && (
                        <>
                          <FontAwesomeIcon icon={faLocationDot} />
                          <span>{place}</span>
                        </>
                      )}
                      {!place && <span className="placeholder">장소</span>}
                    </h2>
                  </article>
                  <ul className="contact">
                    <li
                      ref={e => {
                        arrProfileFormRef.current[4] = e
                      }}
                      className="link">
                      {link && (
                        <span onClick={() => window.open(link, '_blank')}>{link}</span>
                      )}
                      {!link && <span className="placeholder">링크</span>}
                    </li>
                    <li
                      className="email"
                      ref={e => {
                        arrProfileFormRef.current[5] = e
                      }}>
                      {email && (
                        <>
                          <FontAwesomeIcon icon={faEnvelope} /> <span>{email}</span>
                        </>
                      )}
                      {!email && <span className="placeholder">이메일</span>}
                    </li>
                    {/* <li
                      ref={e => {
                        arrProfileFormRef.current[7] = e
                      }}>
                      {status && <span>{status}</span>}
                      {!status && <span className="placeholder">상태</span>}
                    </li> */}
                  </ul>
                </div>
                <div className="profile-back">
                  <div
                    ref={e => {
                      arrProfileFormRef.current[6] = e
                    }}
                    className="intro">
                    {intro && <span>{intro}</span>}
                    {!intro && <span className="placeholder">소개</span>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ProfileAddForm
