import axios from 'axios'

const client = axios.create()

client.interceptors.response.use(config => {
  if (config.data.status === 'expire') {
    console.log('토큰이 만료되었습니다.')
  }
  return config
})

export default client
