import client from './client'

// 로그인
export const signin = (email: string, password: string) =>
  client.post('/api/auth/signin', {email, password})

// 회원가입
export const signup = (email: string, password: string) =>
  client.post('/api/auth/signup', {email, password})

// 인증 정보 조회 (회원가입, 비밀번호)
export const certGet = (certId: string) => client.get(`/api/auth/cert/${certId}`)

// 인증 처리 (회원가입)
export const certPost = (certId: string) => client.post(`/api/auth/cert/${certId}`)

// 인증 (링크) 보내기
export const certSend = (email: string) => client.post('/api/auth/cert/send', {email})

// 비밀번호 설정 (변경)
export const password = (certId: string, password: string) =>
  client.post('/api/auth/password', {certId, password})

// 사용자 정보 조회
export const userInfo = () => client.get('/api/user/userInfo')

// 회원탈퇴
export const userDelete = (userNo: string) => client.post('/api/user/delete', {userNo})

// 프로필 (사진) 업로드
export const profileUpload = (formData: FormData) =>
  client.post('/api/file/upload', formData, {
    headers: {'Content-Type': 'multipart/form-data'}
  })

// 프로필 목록 조회
export const profileList = (userNo: string) =>
  client.get(`/api/profile/list?userNo=${userNo}`)

// 프로필 공유 목록 조회
export const profileShareList = () => client.get('/api/profile/share/list')

// 프로필 공유 링크 생성
export const profileShareId = () => client.post('/api/profile/share/id')

// 프로필 상세 조회
export const profileOne = (profileNo: string) =>
  client.get(`/api/profile/view/${profileNo}`)

// 프로필 공유 조회
export const profileShare = (shareId: string) =>
  client.get(`/api/profile/share/${shareId}`)

// 프로필 추가
export const profileAdd = (
  typeCd: string,
  name: string,
  nickName: string,
  contact: string,
  link: string,
  email: string,
  job: string,
  place: string,
  status: string,
  intro: string,
  shareCd: string,
  shareId: string,
  memo: string,
  fileId: string
) =>
  client.post('/api/profile/add', {
    typeCd,
    name,
    nickName,
    contact,
    link,
    email,
    job,
    place,
    status,
    intro,
    shareCd,
    shareId,
    memo,
    fileId
  })

// 프로필 삭제
export const profileDel = (profileNo: string) =>
  client.post(`/api/profile/del/${profileNo}`)

// 프로필 수정
export const profileEdit = (
  profileNo: string,
  typeCd: string,
  name: string,
  nickName: string,
  contact: string,
  link: string,
  email: string,
  job: string,
  place: string,
  status: string,
  intro: string,
  shareCd: string,
  shareId: string,
  memo: string,
  fileId: string
) =>
  client.post('/api/profile/edit', {
    profileNo,
    typeCd,
    name,
    nickName,
    contact,
    link,
    email,
    job,
    place,
    status,
    intro,
    shareCd,
    shareId,
    memo,
    fileId
  })
