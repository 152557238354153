import {Action} from 'redux'

// 프로필 타입
export type IProfile = {
  profileNo?: string
  typeCd: string
  name: string
  nickName: string
  contact: string
  link: string
  place: string
  email: string
  job: string
  status: string
  intro: string
  shareCd: string
  shareId: string
  memo: string
  fileId: string
  fileUrl: string
}

export type Profile = IProfile
export type State = {
  profile: Profile
  profiles: Profile[]
}

// 액션 타입
export type PROFILE_LIST = Action<'@profile/LIST'> & {payload: State}
export type PROFILE_ONE = Action<'@profile/ONE'> & {payload: Profile}
export type PROFILE_SHARE = Action<'@profile/SHARE'> & {payload: Profile}
export type PROFILE_ADD = Action<'@profile/ADD'>
export type PROFILE_DEL = Action<'@profile/DEL'>
export type PROFILE_EDIT = Action<'@profile/EDIT'>
export type PROFILE_UPLOAD = Action<'@profile/UPLOAD'> & {payload: Profile}

// 액션 타입 통합
type Actions =
  | PROFILE_LIST
  | PROFILE_ONE
  | PROFILE_SHARE
  | PROFILE_ADD
  | PROFILE_DEL
  | PROFILE_EDIT
  | PROFILE_UPLOAD

// 액션 함수
export const profileList = (payload: State): PROFILE_LIST => ({
  type: '@profile/LIST',
  payload
})

export const profileOne = (payload: Profile): PROFILE_ONE => ({
  type: '@profile/ONE',
  payload
})

export const profileShare = (payload: Profile): PROFILE_SHARE => ({
  type: '@profile/SHARE',
  payload
})

export const profileAdd = (): PROFILE_ADD => ({
  type: '@profile/ADD'
})

export const profileDel = (): PROFILE_DEL => ({
  type: '@profile/DEL'
})

export const profileEdit = (): PROFILE_EDIT => ({
  type: '@profile/EDIT'
})

export const profileUpload = (payload: Profile): PROFILE_UPLOAD => ({
  type: '@profile/UPLOAD',
  payload
})

// 초기값
const initialState: State = {
  profile: {
    profileNo: '',
    typeCd: '',
    name: '',
    nickName: '',
    contact: '',
    place: '',
    link: '',
    email: '',
    job: '',
    status: '',
    intro: '',
    shareCd: '',
    shareId: '',
    memo: '',
    fileId: '',
    fileUrl: ''
  },
  profiles: []
}

// 리듀서
export const reducer = (state: State = initialState, action: Actions) => {
  switch (action.type) {
    case '@profile/LIST':
      return {...state, profiles: action.payload, profile: initialState.profile}
    case '@profile/ONE':
    case '@profile/SHARE':
      return {...state, profile: action.payload}
    case '@profile/ADD':
    case '@profile/DEL':
    case '@profile/EDIT':
      return state
    case '@profile/UPLOAD':
      return {
        ...state,
        profile: {
          ...state.profile,
          fileId: action.payload.fileId,
          fileUrl: action.payload.fileUrl
        }
      }
  }
  return state
}
