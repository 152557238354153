import {FC, SyntheticEvent, useCallback, useEffect, useRef, useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faUser,
  faXmark,
  faLocationDot,
  faEnvelope,
  faRotate,
  faHouse
} from '@fortawesome/free-solid-svg-icons'
import {faClone} from '@fortawesome/free-regular-svg-icons'
import {Profile} from '../../modules/profile'
import Logo from './Logo'
import {useLocation, useNavigate} from 'react-router-dom'
import * as Const from '../../lib/const'
import CopyToClipboard from 'react-copy-to-clipboard'

type ProfileTypeFormProps = {
  profile: Profile
}
const ProfileType: FC<ProfileTypeFormProps> = ({profile: p}) => {
  const [copy, setCopy] = useState<string>('')
  const [copied, setCopied] = useState<boolean>(false)
  const [screen, setScreen] = useState<string>('front')

  const location = useLocation()
  const navigate = useNavigate()

  // Front, Back 스크린
  const handleScreen = useCallback(
    (e: SyntheticEvent) => {
      if (screen === 'front') {
        setScreen('back')
        cardRef.current?.classList.add('rotate')
      } else {
        setScreen('front')
        cardRef.current?.classList.remove('rotate')
      }
    },
    [screen]
  )

  // 닫기
  const handleClose = useCallback((e: SyntheticEvent) => {
    e.stopPropagation()
    window.close()
  }, [])

  // 카피
  const handleCopy = useCallback((e: SyntheticEvent) => {
    e.stopPropagation()
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }, [])

  // 홈
  const handleHome = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      navigate('/')
    },
    [navigate]
  )

  useEffect(() => {
    let link = Const.baseUrl + location.pathname
    setCopy(link)
  }, [location.pathname])

  const cardRef = useRef<HTMLDivElement>(null)

  return (
    <>
      {p.profileNo && (
        <div className="profile-share">
          <div className="profile-type">
            <div className="profile-card" ref={cardRef} onClick={handleScreen}>
              <div className="profile-front">
                {p.profileNo && (
                  <div className="btn-delete" onClick={handleClose}>
                    <FontAwesomeIcon icon={faXmark} style={{color: '#fff'}} size="xl" />
                  </div>
                )}
                <article>
                  <div
                    className={'img ' + (!p.fileUrl && 'img-none')}
                    style={{backgroundImage: `url(${p.fileUrl})`}}>
                    {!p.fileUrl && (
                      <FontAwesomeIcon icon={faUser} style={{color: '#86A5C2'}} />
                    )}
                  </div>
                  <>
                    {p.nickName && <h1>{p.nickName}</h1>}
                    {!p.nickName && <h1 className="placeholder">닉네임</h1>}
                  </>
                  <>
                    {p.job && <h2>{p.job}</h2>}
                    {!p.job && <h2 className="placeholder">직업</h2>}
                  </>
                  <>
                    <h2 className="place">
                      <FontAwesomeIcon icon={faLocationDot} />
                      {p.place && <span>{p.place}</span>}
                      {!p.place && <span className="placeholder">장소</span>}
                    </h2>
                  </>
                </article>
                <ul className="contact">
                  <li className="link">
                    {p.link && (
                      <span onClick={() => window.open(p.link, '_blank')}>{p.link}</span>
                    )}
                    {!p.link && <span className="placeholder">링크</span>}
                  </li>
                  <li className="email">
                    {p.email && (
                      <>
                        <FontAwesomeIcon icon={faEnvelope} /> <span>{p.email}</span>
                      </>
                    )}
                    {!p.email && <span className="placeholder">이메일</span>}
                  </li>
                </ul>
                <div className="profile-control">
                  <span className="home" onClick={handleHome}>
                    <FontAwesomeIcon icon={faHouse} />
                  </span>
                  <span className="copy" onClick={handleCopy}>
                    <CopyToClipboard text={copy}>
                      <FontAwesomeIcon icon={faClone} />
                    </CopyToClipboard>
                  </span>
                  <span className="rotate">
                    <FontAwesomeIcon icon={faRotate} />
                  </span>
                </div>
                <div className="copy-message">
                  {copied && <p>프로필 링크가 복사되었습니다.</p>}
                </div>
              </div>
              <div className="profile-back">
                <div className="intro">{p.intro}</div>
                <div className="profile-control">
                  <span className="rotate">
                    <FontAwesomeIcon icon={faRotate} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!p && (
        <div className="wrapper">
          <div>
            <div className="title">
              <h2>
                <Logo />
              </h2>
            </div>
            <div className="result">
              <p>프로필 정보가 존재하지 않습니다.</p>
              <p>프로필 정보가 변경되었을 수도 있습니다.</p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ProfileType
