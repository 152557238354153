import {useCallback} from 'react'
import SignupForm from '../../components/auth/SignupForm'
import * as L from '../../modules/loading'
import * as api from '../../lib/api'
import {useDispatch} from 'react-redux'

const SingupContainer = () => {
  const dispatch = useDispatch()

  // 회원가입
  const onSignup = useCallback(
    (email: string, password: string, callback?: (result: object) => void) => {
      dispatch(L.setLoading(true))
      api
        .signup(email, password)
        .then(res => {
          if (res) {
            // 회원가입 콜백 처리 (에러 메시지 표시)
            callback && callback(res)
          }
        })
        .finally(() => dispatch(L.setLoading(false)))
    },
    [dispatch]
  )

  return <SignupForm onSignup={onSignup} />
}

export default SingupContainer
