import {useDispatch, useSelector} from 'react-redux'
import ProfileListForm from '../../components/profile/ProfileListForm'
import type {AppState} from '../../store/AppState'
import * as L from '../../modules/loading'
import * as P from '../../modules/profile'
import * as api from '../../lib/api'
import {useCallback, useEffect} from 'react'

const ProfileListContainer = () => {
  const dispatch = useDispatch()
  const {profile, loading, auth} = useSelector<AppState, AppState>(state => state)

  const profileList = useCallback(() => {
    if (auth.userInfo.userNo) {
      dispatch(L.setLoading(true))
      api
        .profileList(auth.userInfo.userNo)
        .then(res => {
          dispatch(P.profileList(res.data))
        })
        .finally(() => dispatch(L.setLoading(false)))
    }
  }, [dispatch, auth.userInfo.userNo])

  useEffect(() => {
    if (auth.userInfo.userNo.length) {
      profileList()
    }
  }, [auth.userInfo.userNo, profileList])

  return (
    <ProfileListForm
      profiles={profile.profiles}
      loading={loading}
      userInfo={auth.userInfo}
    />
  )
}

export default ProfileListContainer
