import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

export default function ScrollToTop() {
  const {pathname, key} = useLocation()

  useEffect(() => {
    // 의존성 배열에 pathname을 넣어주면, 링크를 클릭하여 다른화면으로 이동 시 화면 스크롤을 최상단으로 이동시킨다.
    // 의존성 배열에 key를 넣어주면, 동일화면 링크를 클릭해도 화면 스크롤을 최상단으로 이동시킨다.
    // console.log('scrollTop', key)
    window.scrollTo(0, 0)
  }, [pathname, key])

  return null
}
