import {Routes, Route} from 'react-router-dom'
import NotfoundPage from '../pages/error/NotfoundPage'
import ServerErrorPage from '../pages/error/ServerErrorPage'
import Layout from './Layout'
import LandingPage from '../pages/LandingPage'
import SigninPage from '../pages/auth/SigninPage'
import ProfileListPage from '../pages/profile/ProfileListPage'
import ProfileAddPage from '../pages/profile/ProfileAddPage'
import SignupPage from '../pages/auth/SignupPage'
import RecoveryPage from '../pages/auth/RecoveryPage'
import PrivacyPage from '../pages/policy/PrivacyPage'
import ProfileSharePage from '../pages/profile/ProfileSharePage'
import ServiceyPage from '../pages/policy/ServicePage'
import CertifyPage from '../pages/auth/CertifyPage'
import MyinfoPage from '../pages/auth/MyinfoPage'
import WelcomePage from '../pages/auth/WelcomePage'
import RequireAuth from './RequireAuth'
import {ROLE_USER, ROLE_ADMIN} from '../modules/auth'

export default function RoutesSetup() {
  return (
    <Routes>
      {/* 로그인 */}
      <Route path="/signin" element={<SigninPage />} />
      {/* 회원가입 */}
      <Route path="/signup" element={<SignupPage />} />
      {/* 회원가입 환영 */}
      <Route path="/welcome" element={<WelcomePage />} />
      {/* 회원가입 인증 */}
      <Route path="/certify/:certId" element={<CertifyPage />} />
      {/* 비밀번호 찾기 */}
      <Route path="/recovery" element={<RecoveryPage />} />
      {/* 비밀번호 찾기 */}
      <Route path="/recovery/:certId" element={<RecoveryPage />} />
      <Route path="/" element={<Layout />}>
        <Route index element={<LandingPage />} />
        {/* 마이페이지 */}
        <Route
          path="/myinfo/:mode"
          element={
            <RequireAuth roles={[ROLE_USER, ROLE_ADMIN]}>
              <MyinfoPage />
            </RequireAuth>
          }
        />
        {/* 프로필 목록 */}
        <Route
          path="/profile"
          element={
            <RequireAuth roles={[ROLE_USER, ROLE_ADMIN]}>
              <ProfileListPage />
            </RequireAuth>
          }
        />
        {/* 프로필 등록 */}
        <Route
          path="/profile/add"
          element={
            <RequireAuth roles={[ROLE_USER, ROLE_ADMIN]}>
              <ProfileAddPage />
            </RequireAuth>
          }
        />
        {/* 프로필 수정 */}
        <Route
          path="/profile/edit/:profileNo"
          element={
            <RequireAuth roles={[ROLE_USER, ROLE_ADMIN]}>
              <ProfileAddPage />
            </RequireAuth>
          }
        />
        {/* 개인정보처리방침 / 이용약관 */}
        <Route path="/policy/privacy" element={<PrivacyPage />} />
        <Route path="/policy/service" element={<ServiceyPage />} />
      </Route>
      {/* 프로필 공유 */}
      <Route path="/share/:shareId" element={<ProfileSharePage />} />
      {/* 에러 페이지 - 500 */}
      <Route path="/error" element={<ServerErrorPage />} />
      {/* 에러 페이지 - 404 */}
      <Route path="*" element={<NotfoundPage />} />
    </Routes>
  )
}
