import {useSelector} from 'react-redux'
import load from '../../img/load.svg'
import * as L from '../../modules/loading'
import {AppState} from '../../store/AppState'

export default function Loading() {
  const loading = useSelector<AppState, L.State>(({loading}) => loading)

  return (
    <>
      {loading && (
        <div className="loading">
          <img src={load} />
        </div>
      )}
    </>
  )
}
